import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import { CustomAppBar } from 'components/common/AppBar';
import { Footer } from 'components/common/Footer';

import { ContactSection } from 'pages/home/contact';
import { RequestDemoSection } from 'pages/home/requet-demo';

import JohnnieWilliams from 'assets/home/about/JohnnieWilliams.png';
import KennPalm from 'assets/home/about/KennPalm.png';
import SharonLangWilliams from 'assets/home/about/SharonLangWilliams.png';
import VitalyOpryshko from 'assets/home/about/VitalyOpryshko.png';
import BoySecondRowThird from 'assets/home/features/boySecondRowThird.png';
import GirlFirstRowFirst from 'assets/home/features/girlFirsRowFirst.png';
import GirlFirstRowSecond from 'assets/home/features/girlFrirsRowSecond.png';
import GirlSecondRowSecond from 'assets/home/features/girlSecondRowSecond.png';
import GirlThirdRowFirst from 'assets/home/features/girlThirdRowFirst.png';
import GirlThirdRowSecond from 'assets/home/features/girlThirdRowSecond.png';
import listStatic from 'assets/home/features/listStatic.png';
import reconnectAI from 'assets/home/first/reconnectAI.png';
import CL from 'assets/home/use-cases/CL.png';
import MJ from 'assets/home/use-cases/MJ.png';
import Old from 'assets/home/use-cases/Old.png';
import Young from 'assets/home/use-cases/Young.png';

import { useMediaSize } from '../../hooks/useDeviceDetector';

const MAIN_IMAGES_DESKTOP: string[] = [
  reconnectAI,
  // CL,
  // MJ,
  // Old,
  // Young,
];
const MAIN_IMAGES_MOBILE: string[] = [
  reconnectAI,
];
const SECONDARY_IMAGES_DESKTOP: string[] = [
  CL,
  MJ,
  Old,
  Young,
  BoySecondRowThird,
  GirlFirstRowFirst,
  GirlFirstRowSecond,
  GirlSecondRowSecond,
  GirlThirdRowFirst,
  GirlThirdRowSecond,
  listStatic,
  JohnnieWilliams,
  KennPalm,
  SharonLangWilliams,
  VitalyOpryshko,
];

const SECONDARY_IMAGES_MOBILE: string[] = [
  BoySecondRowThird,
  GirlFirstRowFirst,
  GirlFirstRowSecond,
  GirlSecondRowSecond,
  GirlThirdRowFirst,
  GirlThirdRowSecond,
  listStatic,
  JohnnieWilliams,
  KennPalm,
  SharonLangWilliams,
  VitalyOpryshko,
  CL,
  MJ,
  Old,
  Young,
];

export const MainLayout = () => {
  const { isDesktop } = useMediaSize();
  const [shouldRender, setShouldRender] = useState(false);
  const preloadImages = (imageUrls: string[]): Promise<void[]> => {
    const promises = imageUrls.map((url) => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve();
        img.onerror = () => {
          console.error(`Failed to load image: ${url}`);
          resolve();
        };
      });
    });
    return Promise.all(promises);
  };
  useEffect(() => {
    if (isDesktop) {
      (async () => {
        await preloadImages(MAIN_IMAGES_DESKTOP);
          setShouldRender(true);
        await preloadImages(SECONDARY_IMAGES_DESKTOP);
      })();
    } else {
      (async () => {
        await preloadImages(MAIN_IMAGES_MOBILE);
        setShouldRender(true);
        await preloadImages(SECONDARY_IMAGES_MOBILE);
      })();
    }
  }, []);
  if (!shouldRender) {
    return  null;
  }
  return (
    <Box
      sx={{
        overflowX: 'clip',
        overflowY: 'visible',
      }}
    >
      <CustomAppBar />
      <Outlet />
      <ContactSection />
      <RequestDemoSection />
      <Footer />
    </Box>
  );
};
