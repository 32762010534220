import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { useMediaSize } from 'hooks/useDeviceDetector';
import React, { useRef } from 'react';

import { Box } from '@mui/material';

interface IProps {
  zIndex?: number;
  countOfBalls?: number;
  colors?: string[];
  speed?: number;
  diameter?: number;
  blur?: number;
}

const BackgroundAnimation: React.FC<IProps> = ({
  zIndex = 0,
  countOfBalls = 7,
  colors = [
    'rgba(191, 101, 246, 1)',
    'rgba(23, 141, 250, 1)',
    'rgba(191, 101, 246, 1)',
    'rgba(23, 141, 250, 1)',
    'rgba(191, 101, 246, 1)',
    'rgba(23, 141, 250, 1)',
  ],
  speed = 200,
  diameter = 500,
  blur = 190,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const ballRefs = useRef<HTMLDivElement[]>([]);
  const { isPhone, isTablet, isDesktop } = useMediaSize();

  useGSAP(
    () => {
      const container = containerRef.current;
      if (!container) return;

      let containerWidth = container.offsetWidth;
      let containerHeight = container.offsetHeight;
      const balls = ballRefs.current;

      const updateBallPositions = () => {
        containerWidth = container.offsetWidth;
        containerHeight = container.offsetHeight;
        balls.forEach((ball) => {
          const ballWidth = ball.offsetWidth;
          const ballHeight = ball.offsetHeight;

          let x = Math.random() * (containerWidth - ballWidth);
          let y = Math.random() * (containerHeight - ballHeight);
          let angle = Math.random() * 2 * Math.PI;
          const updatePosition = () => {
            x += Math.cos(angle) * speed * 0.016;
            y += Math.sin(angle) * speed * 0.016;

            if (x <= 0 || x >= containerWidth - ballWidth) {
              angle = Math.PI - angle;
              x = Math.max(0, Math.min(x, containerWidth - ballWidth));
            }
            if (y <= 0 || y >= containerHeight - ballHeight) {
              angle = -angle;
              y = Math.max(0, Math.min(y, containerHeight - ballHeight));
            }

            gsap.set(ball, { x, y, force3D: true });
          };

          gsap.ticker.add(updatePosition);
        });
      };

      updateBallPositions();
      gsap.ticker.fps(60);
    },
    { dependencies: [isPhone, isTablet, isDesktop] }
  );

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'absolute',
        zIndex: zIndex,
        width: 'calc(100vw - 10px)',
        height: '100%',
        top: 0,
        left: 0,
        overflow: 'hidden',
      }}
    >
      {Array.from({ length: countOfBalls }).map((_, index) => (
        <Box
          key={index}
          ref={(el) => {
            //@ts-ignore
            if (el) ballRefs.current[index] = el;
          }}
          sx={{
            position: 'absolute',
            width: diameter,
            height: diameter,
            borderRadius: '50%',
            backgroundColor: colors[index],
            filter: `blur(${blur}px)`,
            willChange: 'transform',
          }}
        ></Box>
      ))}
    </Box>
  );
};

export default BackgroundAnimation;
