import { emailRegexp, requiredMessage } from 'validation/index';
import * as yup from 'yup';

export const CONTACT_SCHEMA = yup.object().shape({
  name: yup.string().trim().max(100, 'Max 100 characters').required(requiredMessage),
  email: yup
    .string()
    .trim()
    .required(requiredMessage)
    .matches(emailRegexp, 'This field must be email'),
  phone: yup.string().trim(),
  message: yup.string().trim().max(500, 'Max 500 characters').required(requiredMessage),
  accept: yup
    .boolean()
    .required('Must be selected')
    .test('accept', 'Must be selected', (value: any) => {
      if (value === true) return true;
    }),
});