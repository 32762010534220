import AvatarInteraction from 'assets/home/Pricing/AvatarInteraction.png';
import ConsultationAndOnboarding from 'assets/home/Pricing/ConsultationAndOnboarding.png';
import Customization from 'assets/home/Pricing/Customization.png';
import ImplementationAndTraining from 'assets/home/Pricing/ImplementationAndTraining.png';
import SubscriptionSignUp from 'assets/home/Pricing/SubscriptionSignUp.png';
import UpdatesAndSupport from 'assets/home/Pricing/UpdatesAndSupport.png';

interface ISubscriptionTiers {
  title: string;
  description: string;
  pack: {
    title: string;
    description: string;
    comingSoon?:boolean
  }[];
  additional: string[];
}

export const SUBSCRIPTION_TIRES: ISubscriptionTiers[] = [
  {
    title: 'B2C',
    description: '(Business to Consumer)',
    pack: [
      {
        title: 'Starter Pack:',
        description: 'First 12 questions - ',
        comingSoon:true
      },
      {
        title: 'Essential Pack:',
        description: '45 questions - ',
        comingSoon:true
      },
      {
        title: 'Advanced Pack:',
        description: '200 questions - ',
        comingSoon:true
      },
      {
        title: 'Pro Pack:',
        description: '550 questions - ',
        comingSoon:true
      },
    ],
    additional: [],
  },
  {
    title: 'B2B',
    description: '(Business to Business)',
    pack: [
      {
        title: 'Starter Business Pack:',
        description: 'First 25 questions - ',
        comingSoon:true
      },
      {
        title: 'Standard Business Pack:',
        description: '500 questions - ',
        comingSoon:true
      },
      {
        title: 'Premium Business Pack:',
        description: '2000 questions - ',
        comingSoon:true
      },
      {
        title: 'Enterprise Business Pack:',
        description: '5500 questions - ',
        comingSoon:true
      },
    ],
    additional: [],
  },
  {
    title: 'Additional Functions or Bundle All Add-Ons',
    description: '',
    pack: [
      {
        title: '',
        description: '',
      },
    ],
    additional: [
      'Healthcare Tracking',
      'Age Regeneration (like Remedy)',
      'Conversational memory (ML-Machine Learning)',
      'Picture morphing',
      'Special occasion gift texts',
      'Custom scripting',
      'Additional avatars',
      'Fully customized avatars',
      'Custom animations & backgrounds',
      'Voice cloning / customization',
      'API integration',
    ],
  },
];

interface IHowItWorks {
  title: string;
  description: string;
  image: string;
}

export const HOW_IT_WORK: IHowItWorks[] = [
  {
    title: 'Subscription Sign-Up',
    description:
      'Users can sign up on our website or mobile app, selecting the plan that best suits their needs',
    image: SubscriptionSignUp,
  },
  {
    title: 'Avatar Interaction',
    description:
      'Users can interact with chosen avatars through our web interface or mobile app, engaging in conversations and exploring content',
    image: AvatarInteraction,
  },
  {
    title: 'Customization',
    description:
      'Users can personalize their experience, selecting avatars based on their interests and preferences',
    image: Customization,
  },
  {
    title: 'Consultation and Onboarding',
    description:
      "Initial consultation to understand the client's needs, followed by a tailored onboarding process to integrate our solutions with their systems",
    image: ConsultationAndOnboarding,
  },
  {
    title: 'Implementation and Training',
    description:
      'Smooth implementation of the solution with training sessions for staff to maximize the benefits of the AI avatars',
    image: ImplementationAndTraining,
  },
  {
    title: 'Updates and Support',
    description:
      'Regular updates ensure new content and features are continually added. Customer support is available to assist with any issues or queries',
    image: UpdatesAndSupport,
  },
];
