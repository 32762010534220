import React from 'react';

import { Box, Typography } from '@mui/material';

import BackgroundAnimation from 'components/common/Balls';
import { HowItWorks } from 'components/home/pricing/HowItWorks';
import { SubscriptionTiers } from 'components/home/pricing/SubscriptionTiers';

import { HOW_IT_WORK, SUBSCRIPTION_TIRES } from './consts';
import { useMediaSize } from 'hooks/useDeviceDetector';

export const PricingSection = () => {
  const { isPhone } = useMediaSize();
  return (
    <Box
      className="pricing_section"
      id="pricing"
      sx={{
        overflow: 'hidden',
        position: 'relative',
        p: {
          xs: '100px 20px 50px 20px',
          md: '120px 20px 70px 20px',
        },
      }}
    >
      <BackgroundAnimation speed={isPhone ? 100 : 150}
                           blur={isPhone ? 90 : 190}
                           diameter={isPhone ? 250 : 500}
                           countOfBalls={isPhone ? 5 : 5}
                           zIndex={-1} />
      <Box
        sx={{

          display: 'flex',
          flexDirection: 'column',
          gap: { xs: '48px', sm: '56px', md: '100px' },
          position: 'relative',
          maxWidth: '1620px',
          margin: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          className="pricing_section_title"
          sx={{
            fontSize: { xs: '32px', sm: '48px', md: '64px' },
            fontWeight: { xs: 800, sm: 800, md: 800 },
            lineHeight: { xs: '39.2px', sm: '58.8px', md: '78.4px' },
            textAlign: 'center',
          }}
        >
          Who we work
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <Typography
            className="pricing_section_subtitle"
            sx={{
              fontSize: { xs: '24px', sm: '32px', md: '32px' },
              fontWeight: { xs: 800, sm: 800, md: 800 },
              lineHeight: { xs: '29.4px', sm: '39.2px', md: '39.2px' },
              textAlign: 'center',
            }}
          >
            Subscription Tiers
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '32px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: { xs: '16px', sm: '20px', md: '30px' },
                justifyContent: 'center',
                flexWrap: { xs: 'wrap', lg: 'nowrap' },
              }}
            >
              {SUBSCRIPTION_TIRES.map((item, index) => (
                <SubscriptionTiers
                  key={index}
                  title={item.title}
                  description={item.description}
                  pack={item.pack}
                  additional={item.additional}
                />
              ))}
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
                textAlign: 'left',
              }}
            >
              {/**Note: The prices listed are approximate and will be finalized in the near future.*/}
              *Note: Current prices will be updated in the near future.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            maxWidth: '1620px',
            margin: 'auto',
          }}
        >
          <Typography
            className="pricing_section_second_title"
            sx={{
              fontSize: { xs: '24px', sm: '32px', md: '42px' },
              fontWeight: { xs: 800, sm: 800, md: 800 },
              lineHeight: { xs: '29.4px', sm: '39.2px', md: '51.45px' },
              textAlign: 'center',
            }}
          >
            How It Works
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {HOW_IT_WORK.map((item) => (
              <HowItWorks
                key={item.title}
                description={item.description}
                title={item.title}
                image={item.image}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
