import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useState } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { UseCasesCard } from 'components/home/use-cases/UseCasesCard';

import { USE_CASES_DATA } from './consts';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

export const UseCasesSection = () => {
  const images = USE_CASES_DATA.map((data) => ({
    imageSourceStatic: data.imageSourceStatic,
  }));
  const [currentImage, setCurrentImage] = useState<string>('');
  const [isVisible, setIsVisible] = useState(false);
  const changeImage = (newImage: string) => {
    setIsVisible(false);
    setTimeout(() => {
      setCurrentImage(newImage);
      setIsVisible(true);
    }, 200);
  };
  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add('(min-width: 1024px)', () => {
      gsap.set([`.usecases_left0`, `.usecases_left1`, `.usecases_left2`, `.usecases_left3`], {
        opacity: 0,
      });
      gsap.to(`.usecases_left0`, {
        opacity: 1,
        scrollTrigger: {
          trigger: `.usecases_left0`,
          start: '100 center',
          end: 'center top',
          toggleActions: 'play reverse play reverse',
          onEnter: () => {
            !currentImage.includes('MJ') && changeImage(images[0].imageSourceStatic);
          },
          onLeaveBack: () => {
            changeImage('');
          },
        },
      });
      gsap.to(`.usecases_left1`, {
        opacity: 1,
        scrollTrigger: {
          trigger: `.usecases_left1`,
          start: 'start center',
          end: 'center top',
          toggleActions: 'play reverse play reverse',
          onEnter: () => {
            changeImage(images[1].imageSourceStatic);
          },
          onLeaveBack: () => {
            changeImage(images[0].imageSourceStatic);
          },
        },
      });
      gsap.to(`.usecases_left2`, {
        opacity: 1,
        scrollTrigger: {
          trigger: `.usecases_left2`,
          start: 'start center',
          end: 'center top',
          toggleActions: 'play reverse play reverse',
          onEnter: () => {
            changeImage(images[2].imageSourceStatic);
          },
          onLeaveBack: () => {
            changeImage(images[1].imageSourceStatic);
          },
        },
      });
      gsap.to(`.usecases_left3`, {
        opacity: 1,
        scrollTrigger: {
          trigger: `.usecases_left3`,
          start: 'start center',
          end: 'center top',
          toggleActions: 'play reverse play reverse',
          onEnter: () => {
            changeImage(images[3].imageSourceStatic);
          },
          onLeaveBack: () => {
            changeImage(images[2].imageSourceStatic);
          },
        },
      });
    });
  });
  return (
    <Box
      className="usecases_section"
      id="cases"
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: {
          xs: '50px 20px',
          md: '60px 20px',
        },
      }}
    >
      <Box
        sx={{

          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          maxWidth: '1620px',
          boxSizing: 'border-box',
          gap: { xs: '48px', sm: '56px', md: '100px' },
        }}
      >
        <Typography
          className="usecases_section_title"
          sx={{
            fontSize: { xs: '24px', sm: '48px', md: '64px' },
            fontWeight: { xs: 800, sm: 800, xl: 800 },
            lineHeight: { xs: '29.4px', sm: '58.8px', md: '78.4px' },
            textAlign: 'center',
            color: theme.palette.text.primary,
            margin: 'auto',
          }}
        >
          Use Cases for AI Avatar Product
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '56px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '56px',
              flex: 1,
            }}
          >
            {USE_CASES_DATA.map((data, ind) => (
              <UseCasesCard
                key={ind}
                title={data.title}
                description={data.description}
                items={data.items}
                imageSourceStatic={data.imageSourceStatic}
                imageHeight={data.imageHeight}
                imageWidth={data.imageWidth}
                ind={ind}
              />
            ))}
          </Box>
          <Box
            className="usecases_right"
            position="sticky"
            sx={{
              display: { xs: 'none', md: 'block' },
              mixBlendMode: 'plus-lighter',
              maxWidth: '800px',
              width: '100%',
              flex: 1,
              maxHeight: '900px',
              height: '100%',
              borderRadius: '30px',
              top: '5vh',
              opacity: currentImage ? 1 : 0,
            }}
          >
            <Box
              component="img"
              src={currentImage}
              sx={{
                mixBlendMode: 'plus-lighter',
                maxWidth: '800px',
                width: '100%',
                flex: 1,
                maxHeight: '900px',
                height: '100%',
                borderRadius: '30px',
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 0.2s ease-in-out',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
