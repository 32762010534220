import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { MainLayout } from 'components/main-layout';

import { HomePage } from 'pages/home';

import { CookiesSettingsPage } from './cookies-settings';
import { Error } from './error';
import { NotFound } from './not-found';
import { PrivacyPolicyPage } from './privacy-policy';
import Root from './root';
import { TermsConditionsPage } from './terms-conditions';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<Error />}>
      <Route element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="terms-conditions" element={<TermsConditionsPage />} />
        <Route path="cookies-settings" element={<CookiesSettingsPage />} />
      </Route>
    </Route>
  )
);

export default router;
