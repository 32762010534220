import React from 'react';
import { scrollHandler } from 'utils/scrollHandler';

import { Box, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';

import { ReactComponent as Arrow } from 'assets/appBar/arrow.svg';
import firstSectionAnimation from 'assets/home/first/firstSectionAnimation.json';
import reconnectAI from 'assets/home/first/reconnectAI.png';
import Lottie from 'lottie-react';
import { useMediaSize } from 'hooks/useDeviceDetector';

export const FirstSection = () => {
  const { isPhone } = useMediaSize();
  return (
    <Box
      id="home"
      position="relative"
      sx={{
        overflow: 'hidden',
        padding: {
          xs: '0 20px',
          sm: '0 20px',
          md: '0 24px',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          height: '100%',
          top: '0',
          right: '0',
          background: 'rgb(255,255,255,1)',
        }}
      />
      <Box
        sx={{
          margin: 'auto',
          position: 'relative',
          height: { xs: '950px', sm: '1080px', md: '1106px' },
          maxWidth: '1920px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            zIndex: -1,
            borderRadius: '50%',
            width: { xs: '1054px', md: '1546px' },
            height: { xs: '1054px', md: '1546px' },
            top: '-167px',
            left: { xs: '0%', sm: '40%', md: '40%' },
            filter: 'blur(500px)',
            willChange: 'transform',
            background: 'rgba(23, 141, 250, 1)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            zIndex: -1,
            borderRadius: '50%',
            width: '1046px',
            height: '1046px',
            top: '40%',
            right: { xs: '-30%', sm: '-10%', md: '20%' },
            background: 'rgba(191, 101, 246, 1)',
            filter: 'blur(400px)',
            willChange: 'transform',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: { xs: -5, sm: -30, md: -30 },
            left: '50%',
            transform: 'translateX(-50%)',
            width: { xs: '768px', sm: '1024px', md: '1198px' },
            height: '100%',
            mixBlendMode: 'hard-light',
            overflow: 'hidden',
          }}
        >
          {isPhone
            ? <Lottie
              style={{
                position: 'absolute',
                margin: '0 auto',
                top: 0,
                left: 0,
                width: '90%',
                height: '90%',
                transform: 'translateX(5.5%)',
                objectFit: 'cover',
              }}
              animationData={firstSectionAnimation}
              autoplay={true}
            />
            : <Lottie
              style={{
                position: 'absolute',
                margin: '0 auto',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              animationData={firstSectionAnimation}
              autoplay={true}
            />}
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: { xs: 119, sm: 189, md: 144 },
            left: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '1218px',
            width: '100%',
            height: 'auto',
            mixBlendMode: 'overlay',
            opacity: 0.8,
          }}
          component={'img'}
          src={reconnectAI}
        />
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '140px', sm: '782px', md: '736px' },
            left: { xs: '100%', sm: 0, xl: '7%' },
            '@media(min-width:1650px)': { left: '14.5%' },
            transform: { xs: 'translateX(-95%)', sm: 'translateX(0%)' },
            p: { xs: '16px', sm: '32px', md: '32px'},
            borderRadius: '24px',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: { xs: '190px', sm: '294px', md: '389px' },
              height: '100%',
              borderRadius: 'inherit',
              padding: '2px',
              background: 'linear-gradient(to left, grey, rgba(0, 0, 0, 0.06))',
              WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude',
              zIndex: 1,
            },
            '& > *': {
              position: 'relative',
              zIndex: 2,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '24px', md: '32px' },
              fontWeight: { xs: 500, sm: 500, md: 700 },
              lineHeight: { xs: '24px', sm: '36px', md: '37.57px' },
              textAlign: 'left',
              width: { xs: '158px', sm: '235px', md: '325px' },
            }}
          >
          Reconnect your soul
          </Typography>
        </Box>
        <Box
          sx={{
            borderRadius: '24px',
            position: 'absolute',
            top: { xs: '610px', sm: '751px', md: '723px' },
            right: { xs: '50%', sm: 0, xl: '7%' },
            '@media(min-width:1650px)': { right: '10%' },
            transform: { xs: 'translateX(50%)', sm: 'translateX(0%)' },
            p: '16px',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: { xs: '335px', sm: '297px', md: '402px' },
              height: '100%',
              borderRadius: 'inherit',
              padding: '2px',
              background: 'linear-gradient(to left, grey, rgba(0, 0, 0, 0.02))',
              WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude',
              zIndex: 1,
            },
            '& > *': {
              position: 'relative',
              zIndex: 2,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '14px', md: '16px' },
              fontWeight: { xs: 400, sm: 400, md: 500 },
              lineHeight: { xs: '21px', sm: '21px', md: '24px' },
              textAlign: 'left',
              width: { xs: '311px', sm: '265px', md: '370px' },
            }}
          >
            Creating interactive avatars to help you reconnect with past celebrities, historical
            figures, and deceased relatives, and enhance customer service and psychological support
          </Typography>
        </Box>
        <CustomButton
          variant="contained"
          color="primary"
          sx={{
            width: {xs:'100%', sm:'300px'},
            height: '56px',
            borderRadius: '30px',
            padding: '16px 24px',
            background: 'rgba(30, 30, 30, 1)',
            position: 'absolute',
            // '@media(min-height:930px)': {top: '875px'},
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: { xs: '150px', sm: '100px', md: '150px' },
            '&:hover': {
              borderBottomRightRadius: 0,
              background: 'rgba(30, 30, 30, 1)',
            },
          }}
          onClick={(e) => {
            scrollHandler(e, 'contact');
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '19px',
              textAlign: 'center',
            }}
          >
            Request Demo
          </Typography>
          <Arrow />
        </CustomButton>
      </Box>
    </Box>
  );
};
