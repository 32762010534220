import {useMediaSize} from 'hooks/useDeviceDetector';
import Lottie from 'lottie-react';
import React, {FC, useEffect, useState} from 'react';
import {theme} from 'theme';

import {Box, BoxProps, Typography} from '@mui/material';

interface IProps extends BoxProps {
  children?: React.ReactNode;
  title: string;
  description: string;
  sx?: BoxProps['sx'];
  textBoxSX?: BoxProps['sx'];
  boxWidth?: number;
  boxHeight?: number;
  imageBoxSX: any;
  imageWidth: number;
  imageHeight: number;
  imageSourceStatic: string;
  json?: any;
}

const loadJson = (path: string) => {
  return import(`assets/home/features/animations/${path}`);
};
export const FeaturesCard: FC<IProps> = ({
                                           title,
                                           description,
                                           boxWidth,
                                           boxHeight,
                                           imageWidth,
                                           imageHeight,
                                           imageSourceStatic,
                                           children,
                                           sx,
                                           textBoxSX,
                                           json,
                                           imageBoxSX,
                                           ...rest
                                         }) => {
  const {isMobileDevice} = useMediaSize();
  const [imageSrc, setImageSrc] = useState<boolean>(false);
  const [tilt, setTilt] = useState({x: 0, y: 0});
  const [gradient, setGradient] = useState('');

  const handleMouseMove = (event: any) => {
    if (!isMobileDevice) {
      const {clientX, clientY, currentTarget} = event;
      const {left, top, width, height} = currentTarget.getBoundingClientRect();

      const x = clientX - left - width / 2;
      const y = clientY - top - height / 2;

      const tiltX = -(y / height) * 5;
      const tiltY = (x / width) * 5;
      const x1 = clientX - left;
      const y1 = clientY - top;
      setTilt({x: tiltX, y: tiltY});
      const gradient = `radial-gradient(circle at ${x1}px ${y1}px, rgba(23, 141, 250, .1), rgba(0, 0, 0, .2))`;
      setGradient(gradient);
    }
  };

  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    const loadAnimation = async () => {
      if (json) {
        const module = await loadJson(json);
        setAnimationData(module.default);
      }
    };
    loadAnimation();
  }, [json]);
  return (
    <Box
      className="features_section_cards"
      onMouseMove={handleMouseMove}
      onMouseOut={() => {
        setGradient('');
        setTilt({x: 0, y: 0});
      }}
      sx={{
        p: {xs: '20px'},
        '@media(min-width:1380px)': {p: '32px'},
        display: 'flex',
        flexDirection: 'column',
        '@media(min-width:1400px)': {flexDirection: title === 'Introspective Analysis' ? 'row' : 'column'},
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'rgba(255,255,255,0.05)',
        borderRadius: '24px',
        gap: {xs: '16px', sm: '24px'},
        border: '1px solid rgba(255,255,255,0.2)',
        boxSizing: 'border-box',
        transition: 'transform 0.2s ease-in-out',
        mixBlendMode: imageSrc ? 'normal' : `plus-lighter`,
        transform: !isMobileDevice
          ? `perspective(1000px) rotateX(${tilt.x}deg) rotateY(${tilt.y}deg)`
          : 'none',
        backgroundImage: gradient,
        ...sx,
      }}
      onMouseEnter={() => {
        !isMobileDevice && setImageSrc(true);
      }}
      onMouseLeave={() => {
        !isMobileDevice && setImageSrc(false);
      }}
      {...rest}
    >
      <Box sx={{
        width: '100%'
      }}>
        <Typography
          sx={{
            fontSize: {xs: '24px', sm: '32px', md: '32px'},
            fontWeight: {xs: 800, sm: 800, md: 800},
            lineHeight: {xs: '29.4px', sm: '39.2px', md: '39.2px'},
            textAlign: 'left',
            width: '100%',
            color: theme.palette.text.primary,
            alignSelf: 'start',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: '14px', md: '16px'},
            fontWeight: {xs: 400, md: 500},
            lineHeight: {xs: '21px', md: '24px'},
            textAlign: 'left',
            width: '100%',
            mt: '8px',
            color: theme.palette.text.primary,
            alignSelf: 'start',
          }}
        >
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          '@media(min-width:0 px)': {
            maxHeight: imageHeight - 10,
            maxWidth: imageWidth - 10,
            width: '100%',
            height: '100%',
            background: 'white',
          },
          border: '1px solid rgba(255,255,255,0.2)',
          borderRadius: {xs: '20px', sm:'24px'},
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          ...imageBoxSX,
        }}
      >
        {!imageSrc && (
          <Box
            component="img"
            src={!imageSrc ? imageSourceStatic : imageSrc}
            sx={{
              position: {xs: 'relative', md: 'absolute'},
              top: 0,
              left: 0,
              objectFit: 'cover',
              maxWidth: `${imageWidth}px`,
              width: '100%',
              maxHeight: `${imageHeight}px`,
              height: '100%',
            }}
          />
        )}
        {imageSrc && !isMobileDevice && animationData && (
          <Lottie
            style={{
              mixBlendMode: 'plus-lighter',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            animationData={animationData}
            autoplay={true}
          />
        )}
      </Box>
    </Box>
  );
};
