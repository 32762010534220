import React, { FC } from 'react';
import { theme } from 'theme';

import { Box, BoxProps, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';

import { ReactComponent as Arrow } from 'assets/appBar/arrow.svg';

interface IProps extends BoxProps {
  title: string;
  description: string;
  items: string[];
  imageWidth: number;
  imageHeight: number;
  imageSourceStatic: string;
  ind: number;
}

export const UseCasesCard: FC<IProps> = ({
  title,
  description,
  items,
  imageSourceStatic,
  ind,
}) => {
  return (
    <Box
      className={`usecases_left${ind} usecases_section_card`}
      sx={{
        color: theme.palette.text.primary,
        pb: {
          xs: 0,
          md: ind === 3 ? '100px' : '400px',
        },
        pt: {
          xs: 0,
          md: ind === 0 ? '300px' : '150px',
        },
        flex: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '20px', sm: '24px', md: '48px' },
      }}
    >
      <Box className="usecases_section_card_text">
        <Typography
          sx={{
            fontSize: { xs: '24px', sm: '32px', md: '42px' },
            fontWeight: { xs: 800, sm: 800, md: 800 },
            lineHeight: { xs: '29.4px', sm: '39.2px', md: '51.45px' },
            textAlign: 'left',
            width: '100%',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '16px', md: '24px' },
            fontWeight: { xs: 500 },
            lineHeight: { xs: '24px', sm: '24px', md: '36px' },
            textAlign: 'left',
            width: '100%',
            mt: '8px',
          }}
        >
          {description}
        </Typography>
      </Box>

      <ul
        className="usecases_section_card_text"
        style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
      >
        {items.map((item, i) => (
          <li key={i} style={{ marginLeft: '-20px' }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                fontWeight: { xs: 400, md: 500 },
                lineHeight: { xs: '21px', md: '24px' },
                textAlign: 'left',
              }}
            >
              {item}
            </Typography>
          </li>
        ))}
      </ul>
      <Box
        className="usecases_section_card_img"
        component="img"
        src={imageSourceStatic}
        sx={{
          display: { xs: 'block', md: 'none' },
          mixBlendMode: 'plus-lighter',
          width: '100%',
          alignSelf: 'center',
          flex: 1,
          border: '1px solid rgba(255,255,255,0.2)',
          borderRadius: '30px',
        }}
      />
      <CustomButton
        className="all_btn"
        variant="contained"
        color="primary"
        sx={{
          alignSelf: { xs: 'center', md: 'flex-start' },
          width: { xs: '100%', sm: '300px', md: '198px' },
          height: '56px',
          borderRadius: '30px',
          padding: '16px 24px',
          background: 'rgba(23, 141, 250, 1)',
          '&:hover': {
            borderBottomRightRadius: 0,
            background: 'rgba(23, 141, 250, 1)',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '19px',
            textAlign: 'center',
          }}
        >
          Request Demo
        </Typography>
        <Arrow />
      </CustomButton>
    </Box>
  );
};
