export interface TermsConditionsParagraphs {
  title: string;
  description: string[];
}

export const TERMS_CONDITIONS_PARAGRAPHS: TermsConditionsParagraphs[] = [
  {
    title: '1. Services',
    description: [
      'Reconnect AI provides a platform where users can:',
      '• Create digital twins of individuals.',
      '• Communicate with past celebrities, historical figures, and deceased relatives.',
      '• Create avatars for selling products or services.',
      'These services are accessible through a subscription model.',
    ],
  },
  {
    title: '2. Account Registration',
    description: [
      '2.1 To use Reconnect AI, you must register and create an account',
      '2.2 You agree to provide accurate, current, and complete information during the registration process.',
      '2.3 You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.',
      '2.4 You agree to notify Reconnect AI immediately of any unauthorized use of your account or any other breach of security.',
    ],
  },
  {
    title: '3. User Conduct',
    description: [
      '3.1 You must be at least 18 years old to use Reconnect AI.',
      '3.2 You agree to use the Platform for lawful purposes only and not to engage in any activity that could harm Reconnect AI, its users, or its reputation.',
      '3.3 All interactions and content generated on the Platform must be respectful and not infringe on the rights of others.',
      '3.4 You agree not to use the Platform to create or distribute any content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.',
    ],
  },
  {
    title: '4. Payment and Subscription',
    description: [
      '4.1 Services are accessible through a subscription model. Payment details and subscription plans are outlined on the Platform.',
      '4.2 Payments will be made through the payment methods specified by Reconnect AI.',
      '4.3 All subscription fees are non-refundable, except as required by law.',
      '4.4 Reconnect AI reserves the right to suspend or terminate your access to services for non-payment or any violations of these Terms.',
    ],
  },
  {
    title: '5. Content Ownership and Rights',
    description: [
      '5.1 By using the Platform, you grant Reconnect AI a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute the content you create.',
      '5.2 You retain ownership of your content but grant Reconnect AI the right to display it on the Platform and in related promotional materials.',
      '5.3 You represent and warrant that you have all necessary rights to grant the licenses described in these Terms.',
    ],
  },
  {
    title: '6. Privacy',
    description: [
      '6.1 Reconnect AI collects and uses your personal information in accordance with its Privacy Policy.',
      '6.2 By using the Platform, you consent to the collection and use of your information as described in the Privacy Policy.',
      '6.3 You agree not to disclose or share your login credentials with any third party.',
    ],
  },
  {
    title: '7. Termination',
    description: [
      '7.1 Reconnect AI reserves the right to terminate or suspend your account at any time for any reason, including but not limited to violations of these Terms.',
      '7.2 Upon termination, your right to use the Platform will immediately cease.',
      '7.3 Any provisions of these Terms that, by their nature, should survive termination shall continue to be in effect.',
    ],
  },
  {
    title: '8. Limitation of Liability',
    description: [
      '8.1 Reconnect AI is provided "as is" without warranties of any kind.',
      '8.2 Reconnect AI and its affiliates, officers, directors, employees, and agents are not liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the Platform.',
      "8.3 In no event shall Reconnect AI's total liability to you for all damages, losses, and causes of action exceed the amount paid by you, if any, for accessing the Platform.",
    ],
  },
  {
    title: '9. Changes to Terms',
    description: [
      '9.1 Reconnect AI may update these Terms from time to time. The updated Terms will be posted on the Platform with the effective date.',
      '9.2 Your continued use of the Platform after any changes indicates your acceptance of the new Terms.',
      '9.3 It is your responsibility to review these Terms periodically.',
    ],
  },
  {
    title: '10. Contact Information',
    description: [
      'For any questions about these Terms, please contact us at:',
      'Reconnect AI LLC',
      '9209 Speerberry Ln.',
      'Cordova, TN 38016, USA',
      'Email: info@reconnectai.org',
      'Phone: +1 (901) 828-1659',
    ],
  },
  {
    title: '11. Governing Law',
    description: [
      'These Terms are governed by and construed in accordance with the laws of the State of Tennessee, without regard to its conflict of law principles.',
    ],
  },
  {
    title: '12. Dispute Resolution',
    description: [
      '12.1 Any disputes arising out of or related to these Terms or the use of the Platform shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.',
      '12.2 The arbitration shall take place in Memphis, Tennessee, and the language of the arbitration shall be English.',
      '12.3 Each party shall bear its own costs related to the arbitration.',
    ],
  },
];
