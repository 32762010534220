import React, { FC } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

interface IProps {
  icon: any;
  description: string;
}

export const UnderCaseTag: FC<IProps> = ({ icon, description }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: { xs: '48px', sm: '64px' },
        gap: '16px',
        borderRadius: '30px',
        border: '1px solid rgba(255,255,255,0.2)',
        background: 'rgba(255,255,255,0.05)',
        p: '8px 16px',
        boxSizing: 'border-box',
      }}
    >
      {icon}
      <Typography
        sx={{
          fontSize: { xs: '16px', sm: '24px' },
          fontWeight: { xs: 500 },
          lineHeight: { xs: '24px', md: '36px' },
          color: theme.palette.text.primary,
          whiteSpace: 'nowrap',
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};
