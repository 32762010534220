import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

interface IProps {
  title: string;
  description: string;
  pack: {
    title: string;
    description: string;
    comingSoon?:boolean
  }[];
  additional: string[];
}

export const SubscriptionTiers: FC<IProps> = ({ title, description, pack, additional }) => {
  return (
    <Box
      className="pricing_section_cards"
      sx={{
        display: 'flex',
        flex: {md:'1'},
        flexDirection: 'column',
        p: '32px',
        background: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '24px',
        boxSizing: 'border-box',
        '@media(min-width:0px)': { maxWidth: '520px' },
        '@media(min-width:768px)': { minWidth: '354px',maxWidth: '354px' },
        '@media(min-width:1024px)': { minWidth: '354px',maxWidth: '520px' },
        // '@media(min-width:1240px)': { maxWidth: '370px' },
        // '@media(min-width:1450px)': { maxWidth: '440px' },
        // '@media(min-width:1680px)': { maxWidth: '520px' },
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '24px', sm: '32px', md: '32px' },
            fontWeight: { xs: 800 },
            lineHeight: { xs: '29.4px', sm: '39.2px', md: '39.2px' },
            textAlign: 'left',
            fontFamily: '\'Gilroy\', sans-serif',
          }}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 300,
              lineHeight: '18.45px',
              textAlign: 'left',
              fontFamily: '\'Gilroy\', sans-serif',
            }}
          >
            {description}
          </Typography>
        )}
      </Box>

      {pack.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            mt: '24px',
          }}
        >
          {pack.map((item) => (
            <Box
              key={item.title}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '20px', md: '24px' },
                  fontWeight: { xs: 800 },
                  lineHeight: { xs: '24.5px', md: '29.4px' },
                  textAlign: 'left',
                  fontFamily: 'Gilroy, sans-serif',
                }}
              >
                {item.title}
              </Typography>
              <Box sx={{display: 'flex', alignItems:'center',flexWrap:'wrap'}}>
                <Typography
                  sx={{
                    fontSize: { xs: '20px', md: '24px' },
                    fontWeight: { xs: 300 },
                    lineHeight: { xs: '23px', md: '27.67px' },
                    textAlign: 'left',
                    fontFamily: 'Gilroy, sans-serif',
                  }}
                >
                  {item.description}
                </Typography>
                {item?.comingSoon &&  <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 300,
                    lineHeight: '16.14px',
                    textAlign: 'left',
                    fontFamily: 'Gilroy, sans-serif',
                    ml:'3px'
                  }}
                >
                  coming soon*
                </Typography>}
              </Box>

            </Box>
          ))}
        </Box>
      ) : null}
      {additional.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          {additional.map((item) => (
            <Typography
              key={item}
              sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '22px',
                textAlign: 'left',
                fontFamily: 'Gilroy, sans-serif',
              }}
            >
              - {item}
            </Typography>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};
