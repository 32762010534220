import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import BackgroundAnimation from 'components/common/Balls';
import { CustomButton } from 'components/common/Button';
import { CustomCheckbox } from 'components/common/Checkbox';
import { LinkToMailPhone } from 'components/common/LinkToMailPhone';
import { Popup } from 'components/common/Popup';
import TextInput from 'components/common/TextInput';

import { ReactComponent as EmailIcon } from 'assets/home/contact/emailIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/home/contact/locationIcon.svg';
import { ReactComponent as PhoneIcon } from 'assets/home/contact/phoneIcon.svg';

import { useMediaSize } from '../../../hooks/useDeviceDetector';
import { CONTACT_SCHEMA } from './validation';

interface FormValues {
  name: string;
  email: string;
  phone?: string;
  message: string;
  accept: boolean;
}

export interface IPopupInfo {
  open: boolean;
  title: string;
  description: string;
}

const InvestorsClients = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row', md: 'column' },
      gap: { xs: '20px', md: '32px' },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        flex: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 800,
          lineHeight: '29.4px',
          textAlign: 'left',
        }}
      >
        Investors:
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          textAlign: 'left',
        }}
      >
        We invite visionary investors to join us in driving the future of digital twin technology.
        Your support will help us accelerate innovation, expand our reach, and transform industries
        with our cutting-edge solutions
      </Typography>
    </Box>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        flex: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 800,
          lineHeight: '29.4px',
          textAlign: 'left',
        }}
      >
        Clients:
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          textAlign: 'left',
        }}
      >
        Whether you are a business looking to enhance customer engagement or an individual seeking
        advanced digital solutions, we are here to provide lifelike digital twins that meet your
        needs. Partner with us to unlock new possibilities and create personalized, interactive
        experiences{' '}
      </Typography>
    </Box>
  </Box>
);

export const ContactSection = () => {
  const { isPhone, isMobileDevice } = useMediaSize();
  const [popupInfo, setPopupInfo] = useState<IPopupInfo>({
    open: false,
    title: '',
    description: '',
  });
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [isDisabledCaptcha, setIsDisabledCaptcha] = useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(CONTACT_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      accept: false,
    },
  });
  const onSubmit = async (contacts: FormValues) => {
    try {
      if (recaptchaToken) {
        setIsDisabledButton(true);
        const dataToSend = {
          name: contacts.name,
          email: contacts.email,
          phone: contacts.phone,
          message: contacts.message,
        };
        const formData = new FormData();
        Object.keys(dataToSend).forEach((key) => {
          const value = dataToSend[key as keyof typeof dataToSend];
          if (typeof value !== 'undefined') {
            formData.append(key, value);
          }
        });
        formData.append('g-recaptcha-response', recaptchaToken);
        await axios.post('https://www.reconnectai.org/sendmail/sendemail.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setIsDisabledButton(false);
        reset();
        setPopupInfo({
          open: true,
          title: 'Form submitted',
          description: 'Thank you for contacting us. We will contact you shortly',
        });
        reset();
      } else {
        setIsDisabledCaptcha(true);
      }
    } catch (error: any) {
      setIsDisabledButton(false);
      if (axios.isAxiosError(error) && error?.response) {
        const messageError = error.response?.data?.message;
        if (messageError === 'Captcha not completed, please try again') {
          setRecaptchaToken(null);
          setIsDisabledCaptcha(true);
        } else {
          setPopupInfo({
            open: true,
            title: 'Form not submitted',
            description: messageError || 'Please try again later',
          });
        }
      } else {
        setPopupInfo({
          open: true,
          title: 'Form not submitted',
          description: 'Please try again later',
        });
      }
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    const filteredValue = inputValue.replace(/[^+\d]/g, '').replace(/(?!^)\+/g, '');

    setValue('phone', filteredValue);
  };
  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
        padding: {
          xs: '100px 20px 100px 20px',
          md: '120px 20px 120px 20px',
        },
      }}
      id="contact"
    >
      <BackgroundAnimation speed={isPhone ? 100 : 150} blur={isPhone ? 90 : 190} diameter={isPhone ? 250 : 500} countOfBalls={isPhone ? 5 : 5} zIndex={-1} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1620px',
          margin: 'auto',

          boxSizing: 'border-box',
          position: 'relative',
          gap: { xs: '48px', sm: '56px', md: '100px' },
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '30px', sm: '48px', md: '64px' },
                fontWeight: { xs: 800, sm: 800, md: 800 },
                lineHeight: { xs: '39.2px', sm: '58.8px', md: '78.4px' },
                textAlign: 'center',
              }}
            >
              Join Us on Our Journey
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '36px',
                textAlign: 'center',
              }}
            >
              At Reconnect AI, we are on a mission to revolutionize the way digital interactions are
              experienced. We are actively seeking:
            </Typography>
          </Box>
        </Box>
        {isMobileDevice && <InvestorsClients />}

        <Box
          sx={{
            minHeight: '564px',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '20px', md: '48px' },
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '56px',
              maxWidth: '795px',
              flex: { xs: 1, md: 2 },
            }}
          >
            {!isMobileDevice && <InvestorsClients />}
            <Box sx={{}}>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: 800,
                  lineHeight: '29.4px',
                  textAlign: 'left',
                }}
              >
                Contact Information:
              </Typography>
              <LinkToMailPhone
                action="mailto"
                image={<EmailIcon />}
                description="Email: "
                title="info@reconnectai.org"
              />
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '16px',
                  mt: '18px',
                  alignItems: 'center',
                }}
              >
                <LocationIcon width="19px" />
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: theme.palette.common.black,
                    fontFamily: "'Gilroy', sans-serif",

                  }}
                >
                  Address: 9209 Speerberry Ln. Cordova, TN 38016, USA
                </Typography>
              </Box>
              <LinkToMailPhone
                action="tel"
                image={<PhoneIcon />}
                description="Phone: "
                title="+1(901) 828-1659"
              />
            </Box>
          </Box>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              flex: 1,
              maxWidth: { xs: '100%', sm: '642px' },
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignItems: { xs: 'center', sm: 'start' },
              gap: '20px',
            }}
          >
            <TextInput name="name" label="Name" errors={errors} control={control} />
            <TextInput name="email" label="E-mail" errors={errors} control={control} />
            <TextInput
              name="phone"
              label="Phone"
              errors={errors}
              control={control}
              onChangeHandler={handleChange}
            />
            <TextInput
              name="message"
              label="Message"
              errors={errors}
              control={control}
              multiline
              rows={3}
            />
            <Box>
              <CustomCheckbox
                name="accept"
                label="I accept the Terms"
                errors={errors}
                control={control}
              />
              <Typography
                sx={{
                  textDecoration: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  textAlign: 'left',
                }}
              >
                By pressing “Submit” button you confirm that you have read and accept our
                <Link
                  to="/privacy-policy"
                  style={{
                    textDecoration: 'none',
                    margin: '0 3px',
                    color: theme.palette.primary.main,
                  }}
                >
                  Privacy Policy
                </Link>
                and
                <Link
                  to="/terms-conditions"
                  style={{
                    textDecoration: 'none',
                    margin: '0 3px',
                    color: theme.palette.primary.main,
                  }}
                >
                  Terms & Conditions.
                </Link>
              </Typography>
            </Box>

            <Box
              sx={{
                display: { xs: 'column', md: 'flex' },
                gap: '10px',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <CustomButton
                className="all_btn"
                sx={{
                  width: { xs: '100%', md: '300px' },
                  height: '56px',
                  borderRadius: '30px',
                  mb: '10px',
                  background: 'rgba(23, 141, 250, 1)',
                  '&:hover': {
                    borderBottomRightRadius: 0,
                    background: 'rgba(23, 141, 250, 1)',
                  },
                }}
                type="submit"
                disabled={isDisabledButton}
              >
                Submit
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isDisabledCaptcha && !recaptchaToken}
        onClose={() => setIsDisabledCaptcha(false)}
        PaperProps={{
          sx: {
            width: '298px',
            height: '73px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            m: 0,
            p: 0,
          },
        }}
      >
        <ReCAPTCHA
          sitekey="6LelMCEqAAAAAMakqu2Bk2V5eyYCsGg2I8UIlEae"
          onChange={(e) => setRecaptchaToken(e)}
          size="normal"
        />
      </Dialog>
      <Popup
        open={popupInfo.open}
        title={popupInfo.title}
        description={popupInfo.description}
        onClose={setPopupInfo}
      />
    </Box>
  );
};
