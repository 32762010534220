import React from 'react';
import { parseLinks } from 'utils/parseLinks';

import { Box, Typography } from '@mui/material';

import { COOKIES_SETTINGS_PARAGRAPHS, COOKIES_SETTINGS_PARAGRAPHS_LINKS } from './consts';

export const CookiesSettingsPage = () => {
  return (
    <Box sx={{ p: '100px 24px' }}>
      <Box sx={{ maxWidth: '1303px', margin: 'auto' }}>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '41.6px',
            textAlign: 'center',
          }}
        >
          Reconnect AI Cookie Policy
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '21px',
            textAlign: 'left',
            mt: '56px',
          }}
        >
          Last Updated: June 2024
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
          }}
        >
          {`This Cookie Policy explains how Reconnect AI LLC ("we," "us," or "our") uses cookies and similar tracking technologies on our platform ("Platform"). By using the Platform, you agree to the use of cookies as outlined in this policy.`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', mt: '24px' }}>
          {COOKIES_SETTINGS_PARAGRAPHS.map((paragraph) => (
            <Box key={paragraph.title}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  textAlign: 'left',
                }}
              >
                {paragraph.title}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: '16px', gap: '8px' }}>
                {paragraph.description.map((item) => (
                  <Typography
                    key={item}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      textAlign: 'left',
                    }}
                  >
                    {parseLinks(item, COOKIES_SETTINGS_PARAGRAPHS_LINKS)}
                  </Typography>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
