import {BoxProps} from '@mui/material';
// import GirlFirstRowFirstJson from 'assets/home/features/animations/GirlFirstRowFirstJson.json';
// import GirlFirstRowSecondJson from 'assets/home/features/animations/GirlFirstRowSecondJson.json';
// import GirlThirdRowFirstJson from 'assets/home/features/animations/GirlThirdRowFirstJson.json';
// import BoySecondRowThirdJson from 'assets/home/features/animations/BoySecondRowThirdJson.json';
// import GirlSecondRowSecondJson from 'assets/home/features/animations/GirlSecondRowSecondJson.json';
// import GirlThirdRowSecondJson from 'assets/home/features/animations/GirlThirdRowSecondJson.json';
// import listStaticJson from 'assets/home/features/animations/listStaticJson.json';
import BoySecondRowThird from 'assets/home/features/boySecondRowThird.png';
import GirlFirstRowFirst from 'assets/home/features/girlFirsRowFirst.png';
import GirlFirstRowSecond from 'assets/home/features/girlFrirsRowSecond.png';
import GirlSecondRowSecond from 'assets/home/features/girlSecondRowSecond.png';
import GirlThirdRowFirst from 'assets/home/features/girlThirdRowFirst.png';
import GirlThirdRowSecond from 'assets/home/features/girlThirdRowSecond.png';
import listStatic from 'assets/home/features/listStatic.png';
import BoyFourthRow from 'assets/home/features/boyFourthRow.png';

interface IFeaturesData {
  title: string;
  description: string;
  boxWidth: number;
  boxHeight: number;
  imageBoxSX: any;
  imageWidth: number;
  imageHeight: number;
  imageSourceStatic: string;
  sx?: BoxProps['sx'];
  textBoxSX?: BoxProps['sx'];
  json?: any;
}

export const FEATURES_DATA: IFeaturesData[][] = [
  [
    {
      title: 'Generate avatars from any image or video',
      description:
        'Create lifelike avatars from any image or video that retains the original resemblance',
      boxWidth: 716,
      boxHeight: 800,
      imageBoxSX: {
        '@media(min-width:1024px)': {minWidth: '407px', minHeight: '400px'},
        '@media(min-width:1380px)': {minWidth: '505px', minHeight: '495px'},
        '@media(min-width:1680px)': {minWidth: '642px', minHeight: '631px'},
      },
      imageWidth: 652,
      imageHeight: 641,
      imageSourceStatic: GirlFirstRowFirst,
      sx: {flex: 1.3},
      json: 'GirlFirstRowFirstJson.json',
    },
    {
      title: 'Pre-designed templates for quick setup',
      description:
        'Easily create and customize avatars using our ready-to-use templates for a fast and seamless start',
      boxWidth: 692,
      boxHeight: 800,
      imageBoxSX: {
        '@media(min-width:1024px)': {minWidth: '383px', minHeight: '374px'},
        '@media(min-width:1380px)': {minWidth: '481px', minHeight: '471px'},
        '@media(min-width:1680px)': {minWidth: '618px', minHeight: '607px'},
      },
      imageWidth: 628,
      imageHeight: 617,
      imageSourceStatic: GirlFirstRowSecond,
      sx: {flex: 1},
      json: 'GirlFirstRowSecondJson.json',
    },
  ],
  [
    {
      title: 'Supports 130+ languages',
      description:
        'Communicate globally with avatars that understand and interact in over 130 languages, breaking down language barriers',
      boxWidth: 510,
      boxHeight: 800,
      imageBoxSX: {
        '@media(min-width:1024px)': {minWidth: '234px', minHeight: '298px'},
        '@media(min-width:1380px)': {minWidth: '350px', minHeight: '445px'},
        '@media(min-width:1680px)': {minWidth: '442px', minHeight: '560px'},
      },
      imageWidth: 467,
      imageHeight: 593,
      imageSourceStatic: listStatic,
      sx: {flex: 1},
      textBoxSX: {minHeight: '139px'},
      json: 'listStaticJson.json',
    },
    {
      title: 'Combines several AI solutions',
      description:
        'Leverage the power of multiple AI technologies for enhanced realism, interactivity, and user experience',
      boxWidth: 520,
      boxHeight: 800,
      imageBoxSX: {
        '@media(min-width:1024px)': {minWidth: '230px', minHeight: '298px'},
        '@media(min-width:1380px)': {minWidth: '350px', minHeight: '455px'},
        '@media(min-width:1680px)': {minWidth: '432px', minHeight: '560px'},
      },
      imageWidth: 467,
      imageHeight: 593,
      imageSourceStatic: GirlSecondRowSecond,
      sx: {flex: 1},
      textBoxSX: {minHeight: '139px'},
      json: 'GirlSecondRowSecondJson.json'
    },
    {
      title: 'Chat GPT integration',
      description:
        'Seamlessly integrate advanced conversational AI for intelligent and responsive interactions, enhancing user engagement',
      boxWidth: 510,
      boxHeight: 800,
      imageBoxSX: {
        '@media(min-width:1024px)': {minWidth: '230px', minHeight: '298px'},
        '@media(min-width:1380px)': {minWidth: '350px', minHeight: '455px'},
        '@media(min-width:1680px)': {minWidth: '432px', minHeight: '560px'},
      },
      imageWidth: 467,
      imageHeight: 593,
      imageSourceStatic: BoySecondRowThird,
      sx: {flex: 1},
      textBoxSX: {minHeight: '139px'},
      json: 'BoySecondRowThirdJson.json'
    },
  ],
  [
    {
      title: 'Highly customizable features',
      description:
        'Tailor every aspect of your digital twin to meet specific needs and preferences, ensuring a unique and personalized experience',
      boxWidth: 658,
      boxHeight: 800,
      imageBoxSX: {
        '@media(min-width:1024px)': {minWidth: '300px', minHeight: '310px'},
        '@media(min-width:1380px)': {minWidth: '439px', minHeight: '455px'},
        '@media(min-width:1680px)': {minWidth: '584px', minHeight: '607px'},
      },
      imageWidth: 594,
      imageHeight: 617,
      imageSourceStatic: GirlThirdRowFirst,
      sx: {flex: 1},
      textBoxSX: {minHeight: '139px'},
      json: 'GirlThirdRowFirstJson.json',
    },
    {
      title: 'Real-time interaction capabilities',
      description:
        'Experience real-time interaction capabilities with our digital twins, designed to engage and respond instantly',
      boxWidth: 932,
      boxHeight: 800,
      imageBoxSX: {
        '@media(min-width:1024px)': {minWidth: '536px', minHeight: '382px'},
        '@media(min-width:1380px)': {minWidth: '714px', minHeight: '507px'},
        '@media(min-width:1680px)': {minWidth: '852px', minHeight: '607px'},
      },
      imageWidth: 868,
      imageHeight: 617,
      imageSourceStatic: GirlThirdRowSecond,
      sx: {flex: 1.5},
      textBoxSX: {minHeight: '139px'},
      json: 'GirlThirdRowSecondJson.json'
    },
  ],
  [
    {
      title: 'Introspective Analysis',
      description:
        'Reflect on your life by connecting with your past or future self. Discover what makes you unique. Record your thoughts whenever you like and create a snapshot of your personality. Based on life coaching principles, this model helps you understand and enhance your life.',
      boxWidth: 1620,
      boxHeight: 429,
      imageBoxSX: {
        '@media(min-width:1024px)': {minWidth: '900px', minHeight: '326px'},
        '@media(min-width:1380px)': {minWidth: '1007px', minHeight: '365px'},
        '@media(min-width:1680px)': {minWidth: '1007px', minHeight: '365px'},
      },
      imageWidth: 1007,
      imageHeight: 365,
      imageSourceStatic: BoyFourthRow,
      sx: {flex: 1},
      textBoxSX: {minHeight: '139px'},
      json: 'BoyFourthRowAnimation.json',
    },
  ],
];
