import React, { FC, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { scrollHandler } from 'utils/scrollHandler';

import styles from '../AppBar/AppBar.module.css';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Link as MuiLink, Typography } from '@mui/material';

// import { ReactComponent as LNIcon } from 'assets/common/popupBurger/LNIcon.svg';
// import { ReactComponent as FacebookIcon } from 'assets/common/popupBurger/facebookIcon.svg';
// import { ReactComponent as InstagramIcon } from 'assets/common/popupBurger/instagramIcon.svg';
// import { ReactComponent as PinterestIcon } from 'assets/common/popupBurger/pinterestIcon.svg';
// import { ReactComponent as TwitterIcon } from 'assets/common/popupBurger/twitterIcon.svg';

import { pages } from '../AppBar';
import { CustomButton } from '../Button';

interface IProps {
  toggleDrawer: (newOpen: boolean) => void;
  open: boolean;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PopupBurger: FC<IProps> = ({ toggleDrawer, open, setTrigger }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);
  return (
    <Drawer
      open={open}
      onClose={() => toggleDrawer(false)}
      anchor={'right'}
      sx={{
        '.MuiDrawer-paper': {
          width: '50vw !important',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          p: '20px 28px',
          boxSizing: 'border-box',
          gap: '24px',
        }}
      >
        <IconButton
          edge="end"
          size="large"
          color="primary"
          aria-label="menu"
          onClick={() => toggleDrawer(false)}
          sx={{ alignSelf: 'end', width: '48px', height: '48px' }}
        >
          <CloseIcon sx={{ width: '48px', height: '48px', m: 5, color: '#000000' }} />
        </IconButton>
        <CustomButton
          variant="contained"
          color="primary"
          sx={{
            width: '139px',
            height: '37px',
            borderRadius: '30px',
            padding: '8px 12px',
            background: 'rgba(30, 30, 30, 1)',
            '&:hover': {
              borderBottomRightRadius: 0,
              background: 'rgba(30, 30, 30, 1)',
            },
          }}
          onClick={(e) => {
            scrollHandler(e, 'contact');
            toggleDrawer(false);
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
              textAlign: 'center',
            }}
          >
            Request Demo
          </Typography>
        </CustomButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '24px',
          }}
        >
          {pages.map((page) => (
            <Typography
              className={`${styles.nav_link}`}
              sx={{
                color: 'black',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                textAlign: 'left',
                cursor: 'pointer',
                p: '4px 8px',
              }}
              key={page.text}
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                if (currentPath === '/') {
                  if (page.link === '') {
                    scrollHandler(e, 'home');
                  } else {
                    scrollHandler(e, page.link);
                    setTimeout(() => {
                      setTrigger(true);
                    }, 1000);
                  }
                } else {
                  navigate('/#' + page.link);
                }
                toggleDrawer(false);
              }}
            >
              {page.text}
            </Typography>
          ))}
        </Box>
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    display: 'flex',*/}
        {/*    gap: '30px',*/}
        {/*    flexWrap: 'wrap',*/}
        {/*    alignItems: 'center',*/}
        {/*  }}*/}
        {/*>*/}
          {/*<MuiLink*/}
          {/*  onClick={() => toggleDrawer(false)}*/}
          {/*  href="https://www.linkedin.com/"*/}
          {/*  underline="none"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <FacebookIcon />*/}
          {/*</MuiLink>*/}
          {/*<MuiLink*/}
          {/*  onClick={() => toggleDrawer(false)}*/}
          {/*  href="https://www.linkedin.com/"*/}
          {/*  underline="none"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <PinterestIcon />*/}
          {/*</MuiLink>*/}
          {/*<MuiLink*/}
          {/*  onClick={() => toggleDrawer(false)}*/}
          {/*  href="https://www.linkedin.com/"*/}
          {/*  underline="none"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <InstagramIcon />*/}
          {/*</MuiLink>*/}
          {/*<MuiLink*/}
          {/*  onClick={() => toggleDrawer(false)}*/}
          {/*  href="https://www.linkedin.com/"*/}
          {/*  underline="none"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <LNIcon />*/}
          {/*</MuiLink>*/}
          {/*<MuiLink*/}
          {/*  onClick={() => toggleDrawer(false)}*/}
          {/*  href="https://www.linkedin.com/"*/}
          {/*  underline="none"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <TwitterIcon />*/}
          {/*</MuiLink>*/}
        {/*</Box>*/}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '24px',
          }}
        >
          <Link
            to="/privacy-policy"
            onClick={() => toggleDrawer(false)}
            style={{
              color: theme.palette.text.secondary,
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
            }}
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-conditions"
            onClick={() => toggleDrawer(false)}
            style={{
              color: theme.palette.text.secondary,
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
            }}
          >
            Terms of Service
          </Link>
          <Link
            to="/cookies-settings"
            onClick={() => toggleDrawer(false)}
            style={{
              color: theme.palette.text.secondary,
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
            }}
          >
            Cookies Settings
          </Link>
        </Box>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
            }}
          >
            © Reconnect AI 2024. All rights reserved.
          </Typography>
          <MuiLink
            href="https://www.linkedin.com/showcase/clever-wallet"
            underline="none"
            sx={{ alignSelf: { xs: 'start', sm: 'end' } }}
          ></MuiLink>
        </Box>
      </Box>
    </Drawer>
  );
};
