import React from 'react';

import { Box, Typography } from '@mui/material';

import { PRIVACY_POLICY_PARAGRAPHS } from './consts';

export const PrivacyPolicyPage = () => {
  return (
    <Box sx={{ p: '100px 24px' }}>
      <Box sx={{ maxWidth: '1303px', margin: 'auto' }}>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '41.6px',
            textAlign: 'center',
          }}
        >
          Reconnect AI Privacy Policy
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '21px',
            textAlign: 'left',
            mt: '56px',
          }}
        >
          Last Updated: July 2024
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
          }}
        >
          {`Welcome to Reconnect AI. This Privacy Policy describes how Reconnect AI LLC ("we," "us," or "our") collects, uses, and shares your personal information when you use our platform ("Platform"). By accessing or using Reconnect AI, you agree to the terms of this Privacy Policy.`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', mt: '24px' }}>
          {PRIVACY_POLICY_PARAGRAPHS.map((paragraph) => (
            <Box key={paragraph.title}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  textAlign: 'left',
                }}
              >
                {paragraph.title}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: '16px', gap: '8px' }}>
                {paragraph.description.map((item) => (
                  <Typography
                    key={item}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      textAlign: 'left',
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
