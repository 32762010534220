import { useMediaSize } from 'hooks/useDeviceDetector';
import React, { FC, useState } from 'react';

import { Box, Typography } from '@mui/material';

interface IProps {
  title: {
    function: string;
    fullName: string;
  };
  description: string;
  image: string;
}

export const TeamCard: FC<IProps> = ({ title, description, image }) => {
  const { isMobileDevice } = useMediaSize();
  const [tilt, setTilt] = useState({ x: 0, y: 0 });
  const [gradient, setGradient] = useState('');

  const handleMouseMove = (event: any) => {
    if (!isMobileDevice) {
      const { clientX, clientY, currentTarget } = event;
      const { left, top, width, height } = currentTarget.getBoundingClientRect();

      const x = clientX - left - width / 2;
      const y = clientY - top - height / 2;

      const tiltX = -(y / height) * 5;
      const tiltY = (x / width) * 5;
      const x1 = clientX - left;
      const y1 = clientY - top;
      setTilt({ x: tiltX, y: tiltY });
      const gradient = `radial-gradient(circle at ${x1}px ${y1}px, rgba(23, 141, 250, .1), rgba(0, 0, 0, .2))`;

      setGradient(gradient);
    }
  };
  return (
    <Box
      className="about_section_cards"
      onMouseMove={handleMouseMove}
      onMouseOut={() => {
        setGradient('');
        setTilt({ x: 0, y: 0 });
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: { xs: '16px', sm: '24px' },
        minHeight: '548px',
        '@media(min-width:0px)': { maxWidth: '520px' },
        '@media(min-width:768px)': { maxWidth: '354px' },
        '@media(min-width:1240px)': { maxWidth: '370px' },
        '@media(min-width:1450px)': { maxWidth: '440px' },
        '@media(min-width:1680px)': { maxWidth: '520px' },
        width: '100%',
        borderRadius: '24px',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        background: 'rgba(255, 255, 255, 0.1)',
        p: { xs: '20px', md: '32px' },
        boxSizing: 'border-box',
        transform: `perspective(1000px) rotateX(${tilt.x}deg) rotateY(${tilt.y}deg)`,
        backgroundImage: gradient,
      }}
    >
      <Box
        sx={{
          maxWidth: '250px',
          width: '100%',
          maxHeight: '276px',
          height: '100%',
        }}
        component="img"
        src={image}
        alt="avatar"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          alignItems: 'left',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: '24px', sm: '32px' },
              fontWeight: { xs: 500, sm: 700 },
              lineHeight: { xs: '36px', sm: '37.57px' },
              textAlign: 'center',
            }}
          >
            {title.function}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '24px', sm: '32px' },
              fontWeight: { xs: 500, sm: 700 },
              lineHeight: { xs: '36px', sm: '37.57px' },
              textAlign: 'center',
            }}
          >
            {title.fullName}
          </Typography>
        </Box>

        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'center',
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
