import { gsap } from 'gsap';
import React, { useEffect, useRef } from 'react';
import { theme } from 'theme';

import { Box } from '@mui/material';

import { UnderCaseTag } from 'components/home/under-case/UnderCaseTag';

import { ReactComponent as AdaptiveLearning } from 'assets/home/under-case/adaptiveLearning.svg';
import { ReactComponent as Availability } from 'assets/home/under-case/availability.svg';
import { ReactComponent as FullyCustomizable } from 'assets/home/under-case/fullyCustomizable.svg';
import { ReactComponent as InstantResponses } from 'assets/home/under-case/instantResponses.svg';
import { ReactComponent as PersonalizedCommunication } from 'assets/home/under-case/personalizedCommunication.svg';
import { ReactComponent as RealisticInteractions } from 'assets/home/under-case/realisticInteractions.svg';

import { useMediaSize } from '../../../hooks/useDeviceDetector';

export const UnderCaseSection: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isPhone } = useMediaSize();

  const UNDER_CASE_DATA = [
    {
      icon: <Availability width={isPhone ? '32px' : '48px'} height={isPhone ? '32px' : '48px'} />,
      description: '24/7 Availability',
    },
    {
      icon: (
        <InstantResponses width={isPhone ? '32px' : '48px'} height={isPhone ? '32px' : '48px'} />
      ),
      description: 'Instant Responses',
    },

    {
      icon: (
        <PersonalizedCommunication
          width={isPhone ? '32px' : '48px'}
          height={isPhone ? '32px' : '48px'}
        />
      ),
      description: 'Personalized Communication',
    },
    {
      icon: (
        <AdaptiveLearning width={isPhone ? '32px' : '48px'} height={isPhone ? '32px' : '48px'} />
      ),
      description: 'Adaptive Learning',
    },
    {
      icon: (
        <RealisticInteractions
          width={isPhone ? '32px' : '48px'}
          height={isPhone ? '32px' : '48px'}
        />
      ),
      description: 'Realistic Interactions',
    },
    {
      icon: (
        <FullyCustomizable width={isPhone ? '32px' : '48px'} height={isPhone ? '32px' : '48px'} />
      ),
      description: 'Fully Customizable',
    },
  ];
  useEffect(() => {
    const container = containerRef.current;
    const wrapper = wrapperRef.current;
    if (container && wrapper) {
      const clone = container.innerHTML;
      container.innerHTML += clone;

      const width = container.scrollWidth / 4;
      const duration = 10;

      gsap.fromTo(
        container,
        { x: 0 },
        {
          x: -width,
          ease: 'linear',
          duration: duration,
          repeat: -1,
          modifiers: {
            x: gsap.utils.unitize((x) => parseFloat(x) % width),
          },
        }
      );
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        ref={wrapperRef}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          maxWidth: '1920px',
          margin: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <Box
          ref={containerRef}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: { xs: '16px', sm: '30px' },
            minHeight: { xs: '198px', sm: '214px', md: '244px' },
            p: { xs: '50px 0 100px 0', md: '60px 0 120px 0' },
            boxSizing: 'border-box',
          }}
        >
          {UNDER_CASE_DATA.map((tag, index) => (
            <UnderCaseTag key={index} description={tag.description} icon={tag.icon} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
