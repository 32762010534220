import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'theme';

import styles from './Footer.module.css';

import { Box, Divider, Typography } from '@mui/material';
// import { Link as MuiLink } from '@mui/material';

// import { ReactComponent as LNIcon } from 'assets/footer/LNIcon.svg';
// import { ReactComponent as FacebookIcon } from 'assets/footer/facebookIcon.svg';
// import { ReactComponent as InstagramIcon } from 'assets/footer/instagramIcon.svg';
import { ReactComponent as Logo } from 'assets/footer/logoWhite.svg';
// import { ReactComponent as PinterestIcon } from 'assets/footer/pinterestIcon.svg';
// import { ReactComponent as TwitterIcon } from 'assets/footer/twitterIcon.svg';

import { scrollHandler } from '../../../utils/scrollHandler';
import { pages } from '../AppBar';

export const Footer = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: theme.palette.common.black,
        position: 'relative',
      }}
      id="footer"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '1620px',
          margin: 'auto',
          p: {
            xs: '50px 20px',
            md: '60px 20px',
          },
          boxSizing: 'border-box',
          position: 'relative',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            width: '100%',
            gap: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              justifyContent: 'space-between',
            }}
          >
            <Logo
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('');
              }}
            />
            {pages.map((item) => (
              <Typography
                className={styles.link}
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
                key={item.text}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                  if (currentPath === '/') {
                    if (item.link === '') {
                      scrollHandler(e, 'home');
                    } else scrollHandler(e, item.link);
                  } else {
                    navigate('/#' + item.link);
                  }
                }}
              >
                {item.text}
              </Typography>
            ))}
          </Box>
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    display: 'flex',*/}
          {/*    gap: '30px',*/}
          {/*    alignItems: 'center',*/}
          {/*    justifyContent: { xs: 'start', sm: 'end' },*/}
          {/*  }}*/}
          {/*>*/}
            {/*<MuiLink href="https://www.linkedin.com/" underline="none" target="_blank">*/}
            {/*  <FacebookIcon />*/}
            {/*</MuiLink>*/}
            {/*<MuiLink href="https://www.linkedin.com/" underline="none" target="_blank">*/}
            {/*  <PinterestIcon />*/}
            {/*</MuiLink>*/}
            {/*<MuiLink href="https://www.linkedin.com/" underline="none" target="_blank">*/}
            {/*  <InstagramIcon />*/}
            {/*</MuiLink>*/}
            {/*<MuiLink href="https://www.linkedin.com/" underline="none" target="_blank">*/}
            {/*  <LNIcon />*/}
            {/*</MuiLink>*/}
            {/*<MuiLink href="https://www.linkedin.com/" underline="none" target="_blank">*/}
            {/*  <TwitterIcon />*/}
            {/*</MuiLink>*/}
          {/*</Box>*/}
        </Box>
        <Divider sx={{ width: '100%', borderColor: 'white' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            width: '100%',
            gap: '20px',
          }}
        >
          <Box
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              display: 'flex',
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              gap: '30px',
            }}
          >
            <Link
              to="/privacy-policy"
              style={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
              }}
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-conditions"
              style={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
              }}
            >
              Terms of Service
            </Link>
            <Link
              to="/cookies-settings"
              style={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
              }}
            >
              Cookies Settings
            </Link>
          </Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '18px',
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            © Reconnect AI 2024. All rights reserved
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
