import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { Checkbox, FormControlLabel, FormControlLabelProps, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

interface TextInputProps extends FormControlLabelProps {
  control: any;
  name: string;
  size?: 'medium' | 'small';
  errors?: any;
}

export const CustomCheckbox: FC<TextInputProps> = ({
  control,
  name,
  label,
  size,
  errors,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: '-20px'
      }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            label={label}
            {...rest}
            control={
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                color="primary"
                size={size}
              />
            }
          />
        )}
      />
      <Typography
        sx={{
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: 1.66,
          textAlign: 'left',
          color: theme.palette.error.main,
          mt:'4px'
        }}
      >
        {errors?.accept?.message}
      </Typography>
    </Box>
  );
};
