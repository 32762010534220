import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { FeaturesCard } from 'components/home/features/FeaturesCard';

import { FEATURES_DATA } from './consts';

export const FeaturesSection = () => {
  return (
    <Box
      id="features"
      className="features_section"
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: {
          xs: '100px 20px 50px 20px',
          md: '120px 20px 120px 20px',
        },
      }}
    >
      <Box
        sx={{
          margin: 'auto',
          maxWidth: '1620px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          className="features_section_title"
          sx={{
            fontSize: { xs: '30px', sm: '48px', md: '64px' },
            fontWeight: { xs: 800, sm: 800, md: 800 },
            lineHeight: { xs: '39.2px', sm: '58.8px', md: '78.4px' },
            textAlign: 'center',
            color: theme.palette.text.primary,
            margin: 'auto',
            mb: { xs: '48px', sm: '56px', md: '100px' },
          }}
        >
          Features List Section
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            flexWrap: 'wrap',
            gap: { xs: '16px', sm: '20px', md: '30px' },
            width: '100%',
          }}
        >
          {FEATURES_DATA.map((dataArr, ind) => (
            <Box
              key={ind}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                flexWrap: { xs: 'wrap', md: 'nowrap' },
                gap: { xs: '16px', sm: '20px', md: '30px' },
                width: '100%',
                margin: 'auto',
                '@media(min-width:1024px)': { minWidth: '974px' },
                '@media(min-width:1380px)': { minWidth: '1320px' },
                '@media(min-width:1680px)': { minWidth: '1620px' },
              }}
            >
              {dataArr.map((data) => (
                <FeaturesCard
                  key={data.title}
                  title={data.title}
                  description={data.description}
                  sx={data?.sx}
                  textBoxSX={data?.textBoxSX}
                  imageSourceStatic={data.imageSourceStatic}
                  boxWidth={data?.boxWidth}
                  boxHeight={data?.boxHeight}
                  imageWidth={data.imageWidth}
                  imageHeight={data.imageHeight}
                  imageBoxSX={data.imageBoxSX}
                  json={data?.json}
                />
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
