import JohnnieWilliams from 'assets/home/about/JohnnieWilliams.png';
import KennPalm from 'assets/home/about/KennPalm.png';
import SharonLangWilliams from 'assets/home/about/SharonLangWilliams.png';
import VitalyOpryshko from 'assets/home/about/VitalyOpryshko.png';

interface ITaem {
  title: {
    function: string;
    fullName: string;
  };
  description: string;
  image: string;
}

export const TEAM: ITaem[] = [
  {
    title: {
      function: 'Founder & CEO:',
      fullName: 'Johnnie Williams',
    },
    description:
      'With 40 years in IT, Johnnie Williams provides visionary leadership and strategic direction, guiding Reconnect AI to the forefront of digital innovation',
    image: JohnnieWilliams,
  },
  {
    title: {
      function: 'CTO:',
      fullName: 'Kenn Palm',
    },
    description:
      'Kenn Palm brings 20 years of technology leadership to the team, driving innovation and overseeing all technical operations to ensure cutting-edge solutions',
    image: KennPalm,
  },
  {
    title: {
      function: 'Head of Development:',
      fullName: 'Vitaly Opryshko',
    },
    description:
      'With 30 years in high-tech, Vitaly Opryshko leads our development team, pushing the boundaries of technological capabilities and ensuring top-tier performance',
    image: VitalyOpryshko,
  },
  {
    title: {
      function: 'Marketing Lead: :',
      fullName: 'Johnnie Williams',
    },
    description:
      'An experienced marketing expert, Johnnie Williams develops and executes effective strategies to enhance our market presence and reach our target audience',
    image: JohnnieWilliams,
  },
  {
    title: {
      function: 'Advisor:',
      fullName: 'Sharon Lang-Williams',
    },
    description:
      'With 28 years as a Respiratory Therapist in Trauma and Rehab, Sharon Lang-Williams offers valuable insights, enriching our approach with her extensive healthcare experience',
    image: SharonLangWilliams,
  },
];
