export interface PrivacyPolicyParagraph {
  title: string;
  description: string[];
}

export const PRIVACY_POLICY_PARAGRAPHS: PrivacyPolicyParagraph[] = [
  {
    title: '1. Information We Collect',
    description: [
      '1.1 Personal Information: When you create an account, we collect information such as your name, email address, payment details, and any other information you choose to provide.',
      '1.2 Usage Data: We collect information about your interactions with the Platform, including the pages you visit, the features you use, and the actions you take.',
      '1.3 Cookies and Tracking Technologies: We use cookies and similar tracking technologies to collect information about your use of the Platform and to enhance your user experience.',
    ],
  },
  {
    title: '2. How We Use Your Information',
    description: [
      '2.1 To Provide and Improve Our Services: We use your information to operate, maintain, and improve the Platform, ensuring you have the best possible experience.',
      '2.2 To Process Payments: We use your payment information to process transactions related to your subscription and compensate any services provided through the Platform.',
      '2.3 To Communicate with You: We use your contact information to send you updates, promotional materials, and other information related to your use of the Platform.',
      '2.4 To Ensure Security and Compliance: We use your information to detect and prevent fraud, abuse, and violations of our Terms and Conditions.',
      '2.5 Personalization: We use your information to personalize your experience on the Platform, including providing customized content and recommendations based on your preferences and usage patterns.',
    ],
  },
  {
    title: '3. How We Share Your Information',
    description: [
      '3.1 With Service Providers: We may share your information with third-party service providers who help us operate the Platform and provide our services. These service providers are contractually obligated to protect your information and use it only for the purposes we specify.',
      '3.2 With Business Partners: We may share your information with business partners for marketing and promotional purposes, but only with your consent.',
      '3.3 For Legal Reasons: We may disclose your information if required by law or in response to a legal request, such as a subpoena, court order, or government demand.',
      '3.4 Corporate Transactions: In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity.',
    ],
  },
  {
    title: '4. Data Security',
    description: [
      '4.1 Security Measures: We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, access controls, and secure coding practices.',
      '4.2 Data Breach Response: In the event of a data breach, we will notify you and the appropriate authorities as required by law. We will also take steps to mitigate any potential harm caused by the breach.',
    ],
  },
  {
    title: '5. Your Rights',
    description: [
      '5.1 Access and Correction: You have the right to access and correct your personal information. You can update your account information through the Platform.',
      '5.2 Deletion: You have the right to request the deletion of your personal information, subject to certain legal exceptions. We will respond to your request within a reasonable timeframe.',
      '5.3 Opt-Out: You can opt-out of receiving promotional emails from us by following the unsubscribe instructions in those emails. You can also adjust your communication preferences in your account settings.',
      '5.3 Data Portability: You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format.',
    ],
  },
  {
    title: '6. Children\'s Privacy',
    description: [
      'The Platform is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child, we will take steps to delete such information.',
    ],
  },
  {
    title: '7. Changes to This Privacy Policy',
    description: [
      'We may update this Privacy Policy from time to time. The updated Privacy Policy will be posted on the Platform with the effective date. Your continued use of the Platform after any changes indicates your acceptance of the new Privacy Policy.',
    ],
  },
  {
    title: '8. Contact Information',
    description: [
      'If you have any questions about this Privacy Policy, please contact us at:',
      'Reconnect AI LLC',
      '9209 Speerberry Ln.',
      'Cordova, TN 38016, USA',
      'Email: info@reconnectai.org',
      'Phone: +1 (901) 828-1659',
    ],
  },
];
