import React from 'react';
import { theme } from 'theme';

import { Box, Typography, Link } from '@mui/material';

import { CustomButton } from 'components/common/Button';

import { ReactComponent as Arrow } from 'assets/appBar/arrow.svg';
import RequestDemo from 'assets/home/requestDemo/requestDemo.png';

export const RequestDemoSection = () => {
  return (
    <Box
      id="demo"
      sx={{
        position: 'relative',
        zIndex: 0,
        boxSizing: 'border-box',
        p: { xs: '100px 20px', sm: '100px 20px', md: '120px 221px' },
      }}
    >
      <Box
        sx={{
          minWidth: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 1,
          zIndex: -1,
          mixBlendMode: 'overlay',
        }}
        component="img"
        src={RequestDemo}
        alt="background"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '1920px',
          margin: 'auto',

          boxSizing: 'border-box',
          position: 'relative',
          gap: '32px',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '30px', sm: '48px', md: '64px' },
            fontWeight: { xs: 800 },
            lineHeight: { xs: '39.2px', sm: '58.8px', md: '78.4px' },
            textAlign: 'center',
            color: theme.palette.text.primary,
          }}
        >
          Discover the Power of Lifelike Digital Twins!
        </Typography>
        <Link
          sx={{width:{xs:'100%',sm: '300px'}}}
          href="https://calendly.com/jwill03/30min?month=2024-07"
          target="_blank"
          underline="none"
        >
          <CustomButton
            className="all_btn"
            variant="contained"
            color="primary"
            sx={{
              justifySelf: 'center',
              width: { xs: '100%', sm: '300px' },
              height: '56px',
              borderRadius: '30px',
              padding: '16px 24px',
              background: 'rgba(23, 141, 250, 1)',
              '&:hover': {
                borderBottomRightRadius: 0,
                background: 'rgba(23, 141, 250, 1)',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '19px',
                textAlign: 'center',
              }}
            >
              Book a call
            </Typography>
            <Arrow />
          </CustomButton>
        </Link>
      </Box>
    </Box>
  );
};
