import React from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { TeamCard } from 'components/home/about/TeamCard';

import AboutBackground from 'assets/home/about/AboutBackground.png';

import { TEAM } from './consts';

export const AboutSection = () => {
  return (
    <Box
      className="about_section"
      id="about"
      sx={{
        background: theme.palette.background.default,
        position: 'relative',
        zIndex: 0,
        p: {
          xs: '100px 20px',
          md: '120px 20px',
        },
      }}
    >
      <Box
        sx={{
          minWidth: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: { xs: '-50%', sm: '-200%', md: 0 },
          opacity: 1,
          zIndex: -1,
          mixBlendMode: 'overlay',
        }}
        component="img"
        src={AboutBackground}
        alt="background"
      />
      <Box
        sx={{
          position: 'absolute',
          zIndex: -1,
          borderRadius: '50%',
          width: '787px',
          height: '680px',
          top: '50%',
          right: '0%',
          filter: 'blur(400px)',
          willChange: 'transform',
          background: 'rgba(23, 141, 250, 1)',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1620px',

          boxSizing: 'border-box',
          margin: 'auto',
          gap: { xs: '48px', sm: '56px', md: '100px' },
          color: theme.palette.text.primary,
        }}
      >
        <Typography
          className="about_section_title"
          sx={{
            fontSize: { xs: '32px', sm: '48px', md: '64px' },
            fontWeight: { xs: 800 },
            lineHeight: { xs: '39.2px', sm: '58.8px', md: '78.4px' },
            textAlign: 'center',
          }}
        >
          Meet Our Team
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: { xs: '16px', sm: '20px', md: '30px' },
          }}
        >
          {TEAM.map((item, ind) => (
            <TeamCard
              key={ind}
              title={item.title}
              description={item.description}
              image={item.image}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
