import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

interface IProps {
  title: string;
  description: string;
  image: string;
}

export const HowItWorks: FC<IProps> = ({ title, description, image }) => {
  return (
    <Box
      className="pricing_section_gifs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '190px',
        '@media(min-width:0px)': { maxWidth: '520px' },
        '@media(min-width:768px)': { maxWidth: '354px' },
        '@media(min-width:1400px)': { maxWidth: '440px' },
        '@media(min-width:1680px)': { maxWidth: '520px' },
        width: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: '74px', sm: '80px' },
          maxHeight: { xs: '74px', sm: '80px' },
          width: '100%',
        }}
        component="img"
        src={image}
        alt="icon"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: { xs: '20px', md: '32px' },
          gap: '16px',
          alignItems: 'left',
          alignSelf:'start'
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '18px', md: '24px' },
            fontWeight: { xs: 800 },
            lineHeight: { xs: '22px', md: '29.4px' },
            textAlign: 'left',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
