export interface CookieSettingsParagraphs {
  title: string;
  description: string[];
}

export interface ILink {
  phrase: string;
  url: string;
}

export const COOKIES_SETTINGS_PARAGRAPHS: CookieSettingsParagraphs[] = [
  {
    title: '1. What Are Cookies?',
    description: [
      'Cookies are small text files that are placed on your device when you visit a website. They are widely used to make websites work more efficiently and to provide information to the website owners.',
    ],
  },
  {
    title: '2. Types of Cookies We Use',
    description: [
      'We use the following types of cookies on our Platform:',
      '2.1 Essential Cookies: These cookies are necessary for the Platform to function properly. They enable you to navigate the site and use its features, such as accessing secure areas.',
      "2.2 Performance Cookies: These cookies collect information about how visitors use the Platform, such as which pages are visited most often. This helps us improve the Platform's functionality and performance.",
      '2.3 Functionality Cookies: These cookies allow the Platform to remember choices you make (such as your username and language preferences) and provide enhanced, more personalized features.',
      '2.4 Targeting/Advertising Cookies: These cookies are used to deliver advertisements more relevant to you and your interests. They are also used to limit the number of times you see an ad and to measure the effectiveness of advertising campaigns.',
    ],
  },
  {
    title: '3. How We Use Cookies',
    description: [
      'We use cookies to:',
      '• To Improve User Experience: We use cookies to understand how you interact with the Platform, which helps us improve its functionality and performance.',
      '• To Personalize Content: Cookies allow us to provide you with a more personalized experience by remembering your preferences and settings.',
      '• To Analyze Usage: We use cookies to gather statistical information about the usage of the Platform, which helps us understand how users engage with our content and services.',
      '• To Provide Targeted Advertising: Cookies help us deliver relevant advertisements to you based on your interests and online activities.',
    ],
  },
  {
    title: '4. Managing Cookies',
    description: [
      'You can manage or delete cookies using your browser settings. Here’s how you can do it in some popular browsers:',
      '• Google Chrome: Chrome Cookie Settings',
      '• Mozilla Firefox: Firefox Cookie Settings',
      '• Safari: Safari Cookie Settings',
      '• Microsoft Edge: Edge Cookie Settings',
      'Please note that if you choose to block cookies, you may not be able to access all or parts of our Platform, or some features may not function properly.',
    ],
  },
  {
    title: '5. Third-Party Cookies',
    description: [
      'We may also use third-party cookies from service providers that assist us in various functions, including analytics, advertising, and improving user experience. These third parties may use cookies to collect information about your online activities over time and across different websites.',
    ],
  },

  {
    title: '6. Changes to This Cookie Policy',
    description: [
      'We may update this Cookie Policy from time to time. The updated Cookie Policy will be posted on the Platform with the effective date. Your continued use of the Platform after any changes indicates your acceptance of the new Cookie Policy.\n',
    ],
  },
  {
    title: '7. Contact Information',
    description: [
      'If you have any questions about this Cookie Policy, please contact us at:',
      'Reconnect AI LLC',
      '9209 Speerberry Ln.',
      'Cordova, TN 38016, USA',
      'Email: info@reconnectai.org',
      'Phone: +1 (901) 828-1659',
    ],
  },
];

export const COOKIES_SETTINGS_PARAGRAPHS_LINKS: ILink[] = [
  {
    phrase: 'Edge Cookie Settings',
    url: 'https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09',
  },
  {
    phrase: 'Safari Cookie Settings',
    url: 'https://support.apple.com/en-gb/guide/safari/sfri11471/mac',
  },
  {
    phrase: 'Firefox Cookie Settings',
    url: 'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US',
  },
  { phrase: 'Chrome Cookie Settings', url: 'https://support.google.com/chrome/answer/95647' },
];
