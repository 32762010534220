import { ChangeEventHandler, FC, HTMLInputTypeAttribute } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from 'theme';

import TextField, { StandardTextFieldProps } from '@mui/material/TextField';

interface TextInputProps extends StandardTextFieldProps {
  control: any;
  name: string;
  label: string;
  errors?: any;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: number;
  onBlurHandler?: () => void;
  onChangeHandler?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
  size?: 'medium' | 'small';
  type?: HTMLInputTypeAttribute;
}

const TextInput: FC<TextInputProps> = ({
  control,
  errors,
  name,
  label,
  autoFocus,
  onBlurHandler,
  disabled,
  size = 'medium',
  type,
  sx,
  onChangeHandler,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name, ref, onBlur } }) => (
        <TextField
          {...rest}
          onChange={onChangeHandler ?? onChange}
          value={value ?? ''}
          name={name}
          inputRef={ref}
          label={label}
          onBlur={onBlurHandler ?? onBlur}
          autoFocus={autoFocus}
          error={typeof errors === 'string' ? !!errors : !!errors?.[name]}
          helperText={typeof errors === 'string' ? errors : errors?.[name]?.message}
          size={size}
          disabled={disabled}
          type={type}
          fullWidth
          sx={{ minHeight: '70px', height: 'fit-content', ...sx }}
          InputLabelProps={{
            sx: {
              color: theme.palette.common.black,
              '&.Mui-focused': {
                color: 'black',
              },
            },
          }}
          InputProps={{
            sx: {
              background: 'rgba(255, 255, 255, 0.7)',
              color: theme.palette.common.black,
              borderRadius: '15px',
              fontFamily: "'Gilroy', sans-serif",
              '&:focus': { border: '10px' },
            },
          }}
        />
      )}
    />
  );
};

export default TextInput;
