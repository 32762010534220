import CL from 'assets/home/use-cases/CL.png';
import MJ from 'assets/home/use-cases/MJ.png';
import Old from 'assets/home/use-cases/Old.png';
import Young from 'assets/home/use-cases/Young.png';

interface IUseCasesData {
  title: string;
  description: string;
  items: string[];
  imageWidth: number;
  imageHeight: number;
  imageSourceStatic: string;
}

export const USE_CASES_DATA: IUseCasesData[] = [
  {
    title: 'Communicating with Past Celebrities',
    description:
      'Overview: Users can interact with AI avatars of past celebrities, gaining insights, entertainment, and unique experiences',
    items: [
      'Entertainment and Education: Users can engage in conversations with avatars of famous actors, musicians, or authors, learning about their lives, works, and historical contexts',
      'Interactive Interviews: Journalists, bloggers, or enthusiasts can conduct virtual interviews with AI versions of celebrities, generating content for articles, blogs, or videos',
      'Fan Engagement: Fan communities can host Q&A sessions or interactive events with avatars of their favorite celebrities, enhancing fan engagement and community bonding',
    ],
    imageWidth: 795,
    imageHeight: 900,
    imageSourceStatic: MJ,
  },
  {
    title: 'Communicating with Historical Figures',
    description:
      'Overview: Users can converse with AI avatars of historical figures, gaining educational insights and a deeper understanding of history',
    items: [
      'Educational Tool: Schools and universities can use these avatars to provide students with interactive history lessons, where they can ask questions and get detailed responses from historical personalities',
      'Research and Analysis: Historians and researchers can explore historical contexts, decisions, and events by interacting with avatars of historical figures',
      'Cultural Heritage: Museums and cultural institutions can offer interactive experiences where visitors can learn about historical events and figures directly from AI avatars',
    ],
    imageWidth: 795,
    imageHeight: 900,
    imageSourceStatic: CL,
  },
  {
    title: 'Communicating with Deceased Relatives',
    description:
      'Overview: Users can connect with AI avatars of deceased relatives, offering a sense of comfort, remembrance, and emotional support',
    items: [
      'Grief Support: Individuals coping with loss can interact with avatars of their loved ones, helping them manage grief and emotional distress',
      'Family History: Families can preserve and share stories, wisdom, and experiences of their ancestors through AI avatars, creating a living family history',
      'Memorial Services: Virtual memorial services can include interactions with AI avatars of the deceased, providing a unique and personalized way to honor and remember loved ones',
    ],
    imageWidth: 795,
    imageHeight: 900,
    imageSourceStatic: Old,
  },
  {
    title: 'Selling Products or Services',
    description:
      'Overview: Businesses can use known representative AI avatars to enhance customer engagement, support, and sales processes',
    items: [
      'Customer Service: AI avatars can provide 24/7 customer support, answering queries, guiding users through product features, and solving common issues',
      'Virtual Salesperson: AI avatars can act as virtual salespersons, engaging potential customers, explaining product benefits, and assisting in the purchase process',
      'Interactive Marketing: Businesses can create interactive marketing campaigns where users can engage with AI avatars to learn about new products, promotions, or brand stories',
      'Personalized Shopping Experience: Retailers can offer a personalized shopping experience by having AI avatars assist customers in finding products that match their preferences and needs',
    ],
    imageWidth: 795,
    imageHeight: 900,
    imageSourceStatic: Young,
  },
];
