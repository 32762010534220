import { useMediaSize } from 'hooks/useDeviceDetector';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { scrollHandler } from 'utils/scrollHandler';

import styles from './AppBar.module.css';

import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Slide, Toolbar, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';

import { ReactComponent as Arrow } from 'assets/appBar/arrow.svg';
import { ReactComponent as Logo } from 'assets/appBar/logo.svg';

import { PopupBurger } from '../PopupBurger';

interface IPage {
  text: string;
  link: string;
}

export const pages: IPage[] = [
  { text: 'Home', link: '' },
  { text: 'Features', link: 'features' },
  { text: 'Use cases', link: 'cases' },
  { text: 'Pricing', link: 'pricing' },
  { text: 'About us', link: 'about' },
  { text: 'Contact us', link: 'contact' },
];

export const CustomAppBar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [trigger, setTrigger] = useState<boolean>(false);
  const { isMobileDevice } = useMediaSize();
  const [isBackground, setIsBackgroundVisible] = useState<boolean>(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > lastScrollTop) {
      setTrigger(true);
    } else if (scrollTop < lastScrollTop) {
      setTrigger(false);
    }
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        setTimeout(() => {
          setTrigger(true);
        }, 0);
        history.replaceState(null, '', window.location.pathname + window.location.search);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop < 1) {
        setIsBackgroundVisible(false);
      } else {
        setIsBackgroundVisible(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
      timeout={400}
      style={{ background: isBackground ? 'rgba(255,255,255,0.8)' : 'transparent' }}
    >
      <AppBar
        sx={{
          position: 'fixed',
          top: 0,
          height: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: isBackground ? 'translateY(-100%)' : 'translateY(0)',
          backgroundColor: isBackground ? 'transparent' : 'rgba(255, 255, 255, 0.25)',
        }}
      >
        <Toolbar
          sx={{
            height: '100px',
            width: '100%',
            maxWidth: '1620px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: { xs: '19px 20px'},
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '71px',
            }}
          >
            <Logo
              style={{ cursor: 'pointer', minWidth: '62px', minHeight: '81px' }}
              onClick={() => {
                navigate('');
              }}
            />
            <Box
              sx={{
                width: '100%',
                maxWidth: '600px',
                height: '28px',
                gap: '20px',
                display: !isMobileDevice ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {!isMobileDevice &&
                pages.map((page) => (
                  <Typography
                    className={`${styles.nav_link}`}
                    sx={{
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    key={page.text}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                      currentPath === '/'
                        ? scrollHandler(e, page.link)
                        : navigate('/#' + page.link);
                    }}
                  >
                    {page.text}
                  </Typography>
                ))}
            </Box>
          </Box>
          {!isMobileDevice && (
            <CustomButton
              variant="contained"
              color="primary"
              sx={{
                width: '178px',
                height: '48px',
                borderRadius: '30px',
                padding: '16px 24px',
                background: 'rgba(30, 30, 30, 1)',
                '&:hover': {
                  borderBottomRightRadius: 0,
                  background: 'rgba(30, 30, 30, 1)',
                },
              }}
              onClick={(e) => {
                scrollHandler(e, 'contact');
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  textAlign: 'center',
                }}
              >
                Request Demo
              </Typography>
              <Arrow />
            </CustomButton>
          )}
          {isMobileDevice && (
            <IconButton
              edge="end"
              size="large"
              color="primary"
              aria-label="menu"
              onClick={() => toggleDrawer(true)}
            >
              <MenuIcon sx={{ width: '36px', height: '36px' }} />
            </IconButton>
          )}
          <PopupBurger toggleDrawer={toggleDrawer} open={open} setTrigger={setTrigger} />
        </Toolbar>
      </AppBar>
    </Slide>
  );
};
