import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React from 'react';

import { Box } from '@mui/material';

import { AboutSection } from './about';
import { FeaturesSection } from './features';
import { FirstSection } from './first';
import { PricingSection } from './pricing';
import { UnderCaseSection } from './under-case';
import { UseCasesSection } from './use-cases';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

export const HomePage = () => {
  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add('(min-width: 1024px)', () => {
      gsap.from('.pricing_section_cards', {
        scrollTrigger: {
          trigger: '.pricing_section_cards',
          start: '200 bottom',
          end: 'bottom top',
          toggleActions: 'play none none reverse',
        },
        stagger: 0.35,
        scale: 1.2,
        opacity: 0,
        duration: 0.5,
      });
      gsap.from('.about_section_cards', {
        scrollTrigger: {
          trigger: '.about_section_cards',
          start: 'center bottom',
          end: 'bottom top',
          toggleActions: 'play none none reverse',
        },
        scale: 1.3,
        opacity: 0,
        duration: 1,
      });

      document.querySelectorAll('.pricing_section_gifs').forEach((el) => {
        gsap.from(el, {
          scrollTrigger: {
            trigger: el,
            start: '200 bottom',
            end: 'bottom top',
            toggleActions: 'play none none reverse',
          },
          scale: 0.5,
          opacity: 0,
          yPercent: 100,
          duration: 0.5,
        });
      });
    });
    mm.add('(max-width: 1023px)', () => {
      document.querySelectorAll('.usecases_section_card_text').forEach((el) => {
        gsap.from(el, {
          scrollTrigger: {
            trigger: el,
            start: '100 bottom',
            end: 'bottom top',
            toggleActions: 'play none none reverse',
          },
          scale: 0.5,
          opacity: 0,
          duration: 0.5,
        });
      });
      document.querySelectorAll('.usecases_section_card_img').forEach((el) => {
        gsap.from(el, {
          scrollTrigger: {
            trigger: el,
            start: '200 bottom',
            end: 'bottom top',
            toggleActions: 'play none none reverse',
          },
          scale: 0.5,
          opacity: 0,
          duration: 0.5,
        });
      });
      document.querySelectorAll('.pricing_section_cards').forEach((el) => {
        gsap.from(el, {
          scrollTrigger: {
            trigger: el,
            start: '100 bottom',
            end: 'bottom top',
            toggleActions: 'play none none reverse',
          },
          scale: 0.5,
          opacity: 0,
          duration: 0.5,
        });
      });
      document.querySelectorAll('.about_section_cards').forEach((el) => {
        gsap.from(el, {
          scrollTrigger: {
            trigger: el,
            start: '100 bottom',
            end: 'bottom top',
            toggleActions: 'play none none reverse',
          },
          scale: 0.5,
          opacity: 0,
          duration: 0.5,
        });
      });

      document.querySelectorAll('.pricing_section_gifs').forEach((el) => {
        gsap.from(el, {
          scrollTrigger: {
            trigger: el,
            start: 'top bottom',
            end: 'bottom top',
            toggleActions: 'play none none reverse',
          },
          scale: 0.5,
          opacity: 0,
          yPercent: 100,
          duration: 0.5,
        });
      });
    });

    //FEATURES SECTION
    gsap.from('.features_section_title', {
      scrollTrigger: {
        trigger: '.features_section_title',
        start: '200 bottom',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
      },
      scale: 1.2,
      opacity: 0,
      duration: 0.5,
    });
    document.querySelectorAll('.features_section_cards').forEach((el) => {
      gsap.from(el, {
        scrollTrigger: {
          trigger: el,
          start: '300 bottom',
          end: 'bottom 200',
          toggleActions: 'play none none reverse',
        },
        opacity: 0,
        duration: 1,
      });
    });

    //USE CASES SECTION
    gsap.from('.usecases_section_title', {
      scrollTrigger: {
        trigger: '.usecases_section_title',
        start: '200 bottom',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
      },
      scale: 1.2,
      opacity: 0,
      duration: 0.5,
    });
    //PRICING SECTION
    gsap.from('.pricing_section_title', {
      scrollTrigger: {
        trigger: '.pricing_section_title',
        start: '200 bottom',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
      },
      scale: 1.2,
      opacity: 0,
      duration: 0.5,
    });
    gsap.from('.pricing_section_subtitle', {
      scrollTrigger: {
        trigger: '.pricing_section_subtitle',
        start: '200 bottom',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
      },
      scale: 1.2,
      opacity: 0,
      duration: 0.5,
    });
      gsap.from('.pricing_section_second_title', {
      scrollTrigger: {
        trigger: '.pricing_section_second_title',
        start: '200 bottom',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
      },
      scale: 1.2,
      opacity: 0,
      duration: 0.5,
    });
    //ABOUT SECTION
    gsap.from('.about_section_title', {
      scrollTrigger: {
        trigger: '.about_section_title',
        start: '200 bottom',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
      },
      scale: 1.2,
      opacity: 0,
      duration: 0.5,
    });
     //BTNS SECTION
    document.querySelectorAll('.all_btn').forEach((el) => {
      gsap.from(el, {
        scrollTrigger: {
          trigger: el,
          start: '100 bottom',
          end: 'bottom top',
          toggleActions: 'play none none reverse',
        },
        scale: 0.4,
        opacity: 0,
        duration: 1,
      });
    });
  });

  return (
    <Box
      sx={{
        overflowX: 'clip',
        overflowY: 'visible',
      }}
    >
      <FirstSection />
      <FeaturesSection />
      <UseCasesSection />
      <UnderCaseSection />
      <PricingSection />
      <AboutSection />
    </Box>
  );
};
